import { Progress } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import config from '../../Assets/js/config'
import { useSelector } from 'react-redux'
import { socialIcons } from '../../Redux/icons'

export const Personal = ({
    image,
    name,
    shortDesc,
    abilities,
    description,
    nikName,
    social,
    career,
    birth
}) => {
    const icons = useSelector(socialIcons)
    console.log(social)

    return (
        <>
            <section class="bg-100">
                <div class="container">
                    <div class="row g-0 ">
                        <div
                            class="col-lg-4 py-3 py-lg-0 position-relative"
                            style={{ maxHeight: "400px", minHeight: "400px", backgroundPosition: "top" }}

                        >
                            <div
                                className="bg-holder km-personal-nik-name rounded-ts-lg rounded-lg-bs-lg rounded-te-lg rounded-lg-te-0 km-personal-image"
                                style={{ backgroundImage: `url(${config.imageBase}/${image})` }}
                            >
                                {nikName && <div className='personal-nik-name-t'>
                                    {nikName}
                                </div>}

                                <div className='km-career-personal-before'></div>
                                <div className='km-career-personal'>{career}</div>
                            </div>

                        </div>
                        <div
                            class="col-lg-8 px-4 py-5 my-lg-0 bg-white rounded-lg-te-lg rounded-be-lg rounded-bs-lg rounded-lg-bs-0 d-flex align-items-center">
                            <div className='km-container-birth'>
                                <h5>{name}</h5>
                                <h5 className='birth'>متولد: {birth}</h5>
                                <p
                                    class="my-4 km-pers-desc"
                                    data-zanim-xs='{"delay":0.1}'
                                    dangerouslySetInnerHTML={{ __html: shortDesc }}
                                >
                                    {/* {shortDesc} */}
                                </p>
                                {description && <>
                                    <h5 data-zanim-xs='{"delay":0}'>درباره من</h5>
                                    <p
                                        className='km-pers-desc'
                                        dangerouslySetInnerHTML={{ __html: description }}
                                    ></p>
                                </>}

                                {!!abilities.length &&
                                    <div className='km-abilities' data-zanim-xs='{"delay":0}'>
                                        <h5 style={{ textAlign: 'right' }}>مهارت های من</h5>
                                        {abilities.map(item => (
                                            <div className='km-personal-prog'>
                                                {item.title}
                                                <Progress percent={item.value} key={item.id} />
                                            </div>
                                        ))}
                                    </div>}

                                {social && !!social.length &&
                                    <div className='km-abilities mt-5' data-zanim-xs='{"delay":0}'>
                                        <h5 style={{ textAlign: 'right' }}>شبکه های اجتماعی</h5>
                                        <div className='km-social-personal'>
                                            {social.map(item => {
                                                const icon = icons.find(element => element.title === item.title).icon
                                                return (
                                                    <Link target='_blank' to={item.link}>{icon}</Link>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
