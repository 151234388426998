import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { members } from '../Redux/team'
import { MemberCard } from './MemberCard'
import { AnimatePresence } from 'framer-motion';

export const TeamSection = ({ count, random, users }) => {
    // const team = useSelector(members)
    const [teamRandom, setTeamRandom] = useState([])

    useEffect(() => {
        const temp = [...users]
        setTeamRandom(temp.sort(() => 0.3 - Math.random()).slice(0, count))
        let randomInterval
        if (random) {
            randomInterval = setInterval(() => {
                const shuffled = temp.sort(() => 0.3 - Math.random());
                setTeamRandom(shuffled.slice(0, count))
            }, 60000);
        } else {
            setTeamRandom(temp)
        }

        return () => clearInterval(randomInterval)

    }, [])


    return (

        <section class="bg-100 text-center">
            <div class="container">
                <div class="text-center mb-6">
                    <h3 class="fs-2 fs-md-3">اعضای تیم آریو ریتم</h3>
                    <hr
                        class="short"
                        data-zanim-xs='{"from":{"opacity":0,"width":0},"to":{"opacity":1,"width":"4.20873rem"},"duration":0.8}'
                        data-zanim-trigger="scroll"
                    />
                </div>
                <div class="row">
                    <AnimatePresence>
                        {teamRandom.map(item => (
                            <MemberCard item={item} />
                        ))}
                    </AnimatePresence>
                </div>
            </div>
        </section>
    )
}
