import React from 'react'

export const FastContact = () => {
    return (
        <section class="bg-primary py-6 text-center text-md-start">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md">
                        <h4 class="text-white mb-0">
                            به دنبال توسعه کسب و کار خود هستید...
                            <br class="d-md-none" />با ما تماس بگیرید
                        </h4>
                    </div>
                    <div class="col-md-auto mt-md-0 mt-4">
                        <a class="btn btn-light rounded-pill" href="contact.html">تماس سریع با ما</a>
                    </div>
                </div>
            </div>
        </section>
    )
}
