import { createSlice } from '@reduxjs/toolkit'



export const configSlice = createSlice({
    name: 'configStore',
    initialState: {
        preloader: false,
    },

    reducers: {
        setPreloader: (state, action) => {
            state.preloader = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
// export const { } = counterSlice.actions
export const preloader = state => state.configStore.preloader
// Action creators are generated for each case reducer function
export const { setPreloader } = configSlice.actions
export default configSlice.reducer