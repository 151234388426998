import React from 'react'

export const WhyUs = () => {
    return (
        <section>
            <div class="container">
                <div class="text-center mb-7">
                    <h3 class="fs-2 fs-md-3">چرا مارا انتخاب میکنند</h3>
                    <hr
                        class="short"
                        data-zanim-xs='{"from":{"opacity":0,"width":0},"to":{"opacity":1,"width":"4.20873rem"},"duration":0.8}'
                        data-zanim-trigger="scroll"
                    />
                </div>
                <div class="row">
                    <div class="col-lg-6 pe-lg-3">
                        <img
                            class="rounded-3 img-fluid"
                            src={`${process.env.PUBLIC_URL}/Assets/img/allin.jpg`}
                            alt="about"
                        />
                    </div>
                    <div
                        class="col-lg-6 px-lg-5 mt-6 mt-lg-0"
                        data-zanim-timeline="{}"
                        data-zanim-trigger="scroll"
                    >
                        <div class="overflow-hidden">
                            <div class="px-4 px-sm-0" data-zanim-xs='{"delay":0}'>
                                <h5 class="fs-0 fs-lg-1">
                                    <span
                                        class="fas fa-comment-dots fs-1 me-2 mx-2"
                                        data-fa-transform="flip-h"
                                    ></span>تیم برنامه نویسی جوان و خلاق
                                </h5>
                                <p class="mt-3">
                                    آریو ریتم تیمی متشکل از برنامه نویسان جوان ، خلاق و متخصص در حوزه های مختلف برنامه نویسی است. هدف این تیم طراحی ، اجرا و توسعه انواع پروژه های برنامه نویسی در حوزه های مختلف است.
                                </p>
                            </div>
                        </div>
                        <div class="overflow-hidden">
                            <div class="px-4 px-sm-0 mt-5" data-zanim-xs='{"delay":0}'>
                                <h5 class="fs-0 fs-lg-1">
                                    <span
                                        class="fas fa-palette fs-1 me-2 mx-2"
                                        data-fa-transform="shrink-1"
                                    ></span>طراحی منحصر به فرد
                                </h5>
                                <p class="mt-3">
                                    رابط کاربری، ابزار تعاملی کاربر با صفحات وبسایت، نرم افزار، سیستم عامل، اپلیکیشن و …محسوب می‌شوند. رابط کاربری نقش بسیار مهمی در جذب و حفظ مخاطب در یک وبسایت ایفا می‌کند به همین دلیل طراحی اصولی رابط کاربری بسیار مهم و قابل توجه است.
                                </p>
                            </div>
                        </div>
                        <div class="overflow-hidden">
                            <div class="px-4 px-sm-0 mt-5" data-zanim-xs='{"delay":0}'>
                                <h5 class="fs-0 fs-lg-1">
                                    <span
                                        class="fas fa-stopwatch fs-1 me-2 mx-2"
                                        data-fa-transform="grow-1"></span>پشتیبانی فنی ۲۴
                                </h5>
                                <p class="mt-3">
                                    خدمات پشتیبانی فنی آریو ریتم به طور کلی شامل رفع مشکلات و خطاها و باگهای سایت یا سیستم، بررسی مشکلات امنیتی سیستم و رفع آنها، بهبود سرعت ، نصب افزونه های مورد نیاز که بخصوص در پشتیبانی اهمیت دارد، بروز رسانی و رفع حفره های امنیتی و ... می باشد.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
