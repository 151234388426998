import React, { useEffect } from 'react'
import { Banner } from '../Components/About/Banner'
import { Master } from '../Components/About/Master'
import { Emblem } from '../Components/Emblem'
import { TeamSection } from '../Components/TeamSection'
import { FastContact } from '../Components/FastContact'
import withLoading from '../Assets/hooks/withLoading'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

const About = () => {
    const location = useLocation()

    useEffect(() => {
        window.scrollTo({ top: 1, behavior: 'smooth' })
    }, [location.pathname])
    return (
        <>
            <Helmet>
                <title>درباره ما</title>
            </Helmet>
            <Banner title={'درباره ما'} image={'Assets/img/team-slider-1.jpg'} />
            <Master />
            {/* <FastContact /> */}
            <Emblem />
        </>
    )
}

export default withLoading(About)
