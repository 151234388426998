import React, { useEffect } from 'react'
import { Banner } from '../Components/ContactUs/Banner'
import { Address } from '../Components/ContactUs/Contact'
import withLoading from '../Assets/hooks/withLoading'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

const ContactUs = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 1, behavior: 'smooth' })
  }, [location.pathname])
  return (
    <>
      <Helmet>
        <title>تماس با ما</title>
      </Helmet>
      <Banner />
      <Address />
    </>
  )
}


export default withLoading(ContactUs)