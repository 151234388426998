import { configureStore } from '@reduxjs/toolkit'
import teamReducer from './team'
import projectsReducer from './projects'
import adminReducer from './admin'
import iconsReducer from './icons'
import configStoreReducer from './configStore'

export const store = configureStore({
    reducer: {
        team: teamReducer,
        projects: projectsReducer,
        admin: adminReducer,
        icons: iconsReducer,
        configStore: configStoreReducer,
    },
})