import React, { useEffect, useRef, useState } from 'react'
import config from '../../Assets/js/config'
import axios from 'axios'
import { Link, redirect, useLoaderData } from 'react-router-dom'
import { Navbar } from '../../Components/Admin/Navbar'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Skeleton } from 'antd'



export const loader = async () => {
    const token = localStorage.getItem('@token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    try {
        const { data } = await axios.get(`${config.base}/auth/profile`, { headers })
        // const socialMedias = await axios.get(`${config.base}/auth/show-social-medias`, { headers })
        // const abilities = await axios.get(`${config.base}/auth/show-abilities`, { headers })
        if (!data.user || !token) {
            return redirect('/admin/login')
        }

        return { user: data }
    } catch (e) {
        alert('k')
        console.error(e)
        return redirect('/admin/login')
    }
    return null
}
export const EditProfile = () => {
    const token = localStorage.getItem('@token')
    const headers = {
        'Authorization': `Bearer ${token}`,
        "Accept": "application/json",
        "Content-Type": "multipart/form-data",
    }
    const data = useLoaderData()
    const [socialMedias, setSocialMedias] = useState({ loading: true, data: [] })
    const [abilities, setAbilities] = useState({ loading: true, data: [] })
    console.log(data)

    const [userSocial, setUserSocial] = useState([])
    const [userAbility, setUserAbility] = useState([])
    const name = useRef()
    const title = useRef()
    const nickname = useRef()
    const birthday = useRef()
    const [profile, setProfile] = useState(null)
    const [shortDescription, setShortDescription] = useState('')
    const [longDescription, setLongDescription] = useState('')


    const getSocialMedias = async () => {
        try {
            setSocialMedias({ loading: true, data: [] })
            const { data } = await axios.get(`${config.base}/auth/show-social-medias`, { headers })
            setSocialMedias({ loading: false, data: [...data.socialMedias] })
        } catch (e) {
            console.error(e)
            setSocialMedias({ loading: false, data: [] })
        }
    }


    const getAbilities = async () => {
        try {
            setAbilities({ loading: true, data: [] })
            const { data } = await axios.get(`${config.base}/auth/show-abilities`, { headers })
            setAbilities({ loading: false, data: [...data.abilities] })
        } catch (e) {
            console.error(e)
            setAbilities({ loading: false, data: [] })
        }
    }



    useEffect(() => {
        getSocialMedias()
        getAbilities()
    }, [])

    useEffect(() => {
        name.current.value = data.user.user.name
        title.current.value = data.user.user.title
        nickname.current.value = data.user.user.nickname
        birthday.current.value = data.user.user.birthday
        const ab = []
        data.user.abbilities.forEach(item => {
            ab.push({
                id: item.pivot.ability_id,
                value: item.pivot.value,
                elementId: data.user.abbilities.length + Math.random()
            })
        });
        const so = []
        data.user.socialMedia.forEach(item => {
            so.push({
                id: item.pivot.social_media_id,
                link: item.pivot.link,
            })
        });
        setUserAbility(ab)
        setUserSocial(so)
        setShortDescription(data.user.user.shortDescription)
        setLongDescription(data.user.user.longDescription)
    }, [])





    const submitProfile = async () => {

        const formData = new FormData()
        // formData.append('profile', profile)
        // formData.append('name', name.current.value)
        // formData.append('birthday', birthday.current.value)
        // formData.append('nickname', nickname.current.value)
        // formData.append('title', title.current.value)
        // formData.append('shortDescription', shortDescription)
        // formData.append('longDescription', longDescription)
        // formData.append('socialMedias', JSON.stringify(userSocial))
        // formData.append('abilities', JSON.stringify(userAbility))

        try {
            const { data } = await axios.post(`${config.base}/auth/update-profile`, {
                name: name.current.value,
                birthday: birthday.current.value,
                nickname: nickname.current.value,
                title: title.current.value,
                shortDescription,
                longDescription,
                socialMedias: userSocial,
                abilities: userAbility,
                profile: profile,
                // ...formData
            }, { headers })
            console.log(data)
        } catch (e) {
            console.error(e)
        }
    }




    return (
        <div className='km-admin-panel'>

            <nav>
                <div className='right'>
                    <img src={`${config.imageBase}/${data.user.user.profile}`} />
                    <h5>{data.user.user.name}</h5>
                </div>
                <div className='left'>
                    <Link to='/admin/logout'>
                        خروج از حساب کاربری
                    </Link>
                </div>
            </nav>

            <main>
                <Navbar role={data.user.user.role} />

                <div className='left'>
                    <h5>ویرایش حساب کاربری</h5>
                    <div className='update-profile-admin'>
                        <div className='km-km-row'>
                            <div>
                                <h5>نام و نام خانوادگی</h5>
                                <input type='text' ref={name} />
                            </div>
                            <div>
                                <h5>عنوان شغلی</h5>
                                <input type='text' ref={title} />
                            </div>
                            <div>
                                <h5>نام مستعار</h5>
                                <input type='text' ref={nickname} />
                            </div>
                            <div>
                                <h5>تاریخ تولد</h5>
                                <input type='text' ref={birthday} />
                            </div>
                        </div>

                        <div className='km-km-row'>
                            <h5>آپلود تصویر</h5>
                            <input type='file' onChange={e => {
                                const imageFile = e.target.files[0];
                                // const reader = new FileReader();

                                // reader.onload = (event) => {
                                setProfile(imageFile);
                                // };

                                // reader.readAsDataURL(imageFile);
                            }} />
                        </div>


                        <div className='km-kfgyjdvhgsjb-add-social-profile'>
                            <h5>شبکه های اجتماعی</h5>

                            {userSocial.map(item => (
                                <>
                                    <h6>لینک</h6>
                                    <input type='url' value={item.link} placeholder='url -> example.com' onChange={(e) => {
                                        const abId = userSocial.findIndex(element => element.id == item.id)
                                        const temp = [...userSocial]
                                        temp[abId].link = e.target.value
                                        setUserSocial(temp)
                                    }} />
                                    {socialMedias.loading ? 'loading' :
                                        <select
                                            {...(item.id && { defaultValue: item.id })}
                                            onChange={(e) => {
                                                const abId = userSocial.findIndex(element => element.elementId == item.elementId)
                                                const temp = [...userSocial]
                                                temp[abId].id = e.target.value
                                                setUserSocial(temp)
                                            }}>
                                            <option selected disabled>شبکه اجتماعی را انتخاب کنید</option>
                                            {socialMedias.data.map(item => (
                                                <option value={item.id}>{item.title}</option>
                                            ))}
                                        </select>
                                    }
                                </>
                            ))}

                        </div>

                        <div className='add-new-social-profile'>
                            <Button onClick={() => {
                                setUserSocial([...userSocial, {
                                    link: '',
                                    id: '',
                                    elementId: Math.random() * 10 + Math.random() * 9356
                                }])
                            }}>افزودن شبکه اجتماعی جدید</Button>
                        </div>


                        <div className='km-kfgyjdvhgsjb-add-social-profile'>
                            <h5>مهارت ها</h5>

                            {userAbility.map(item => (
                                <>
                                    <h6>سطح مهارت از 0 تا 100 درصد</h6>
                                    <input type='number' placeholder='0 / 10' value={item.value} onChange={(e) => {
                                        const abId = userAbility.findIndex(element => element.elementId == item.elementId)
                                        const temp = [...userAbility]
                                        temp[abId].value = e.target.value
                                        setUserAbility(temp)
                                    }} />
                                    {abilities.loading ? 'loading' :
                                        <select
                                            className='km-admin-social-table'
                                            {...(item.id && { defaultValue: item.id })}
                                            onChange={(e) => {
                                                const abId = userAbility.findIndex(element => element.elementId == item.elementId)
                                                const temp = [...userAbility]
                                                temp[abId].id = e.target.value
                                                setUserAbility(temp)
                                            }}
                                        >
                                            <option selected disabled>مهارت را انتخاب کنید</option>
                                            {abilities.data.map((item, index) => (
                                                <option value={item.id}>{item.title}</option>
                                            ))}
                                        </select>}
                                </>

                            ))}

                        </div>

                        <div className='add-new-social-profile'>
                            <Button onClick={() => {
                                setUserAbility([...userAbility, {
                                    id: '',
                                    value: '',
                                    elementId: Math.random() * 10 + Math.random() * 9356
                                }])
                            }}>افزودن مهارت جدید</Button>
                        </div>


                        <div>
                            <h5>توضیحات کوتاه</h5>
                            <CKEditor
                                config={{ language: 'fa', }}
                                {...(shortDescription && { data: shortDescription })}
                                editor={ClassicEditor}
                                onChange={(event, editor) => setShortDescription(editor.getData())}
                            />
                        </div>
                        <div>
                            <h5>توضیحات تکمیلی</h5>
                            <CKEditor
                                config={{ language: 'fa', }}
                                {...(longDescription && { data: longDescription })}
                                editor={ClassicEditor}
                                onChange={(event, editor) => setLongDescription(editor.getData())}
                            />
                        </div>
                        <Button onClick={submitProfile} className='km-update-profile-submit'>ثبت اطلاعات</Button>
                    </div>
                </div>

            </main>

        </div>
    )
}
