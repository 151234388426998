import { Modal } from 'antd'
import axios from 'axios'
import React, { useRef, useState } from 'react'
import config from '../../Assets/js/config'

export const AddServiceModal = ({ open, setOpen, getServices }) => {
    const title = useRef()
    const token = localStorage.getItem('@token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    const [loading, setLoading] = useState(false)


    const addService = async () => {
        if (!title.current.value) {
            title.current.style.border = '1px solid red'
            return
        }
        title.current.style.border = '1px solid black'
        if (!loading) {
            try {
                setLoading(true)
                const { data } = await axios.post(`${config.base}/auth/add-service`, {
                    title: title.current.value,
                }, { headers })
                setLoading(false)
                setOpen(false)
                getServices()
                title.current.value = ''
                console.log(data)
            } catch (e) {
                console.error(e)
                setLoading(false)
            }
        }
    }

    return (
        <Modal
            title="افزودن عضو"
            open={open}
            onOk={addService}
            onCancel={() => setOpen(false)}
            cancelText='بیخیال'
            okText='افزودن'
        >
            <div className='add-member-modal-form'>
                <label>عنوان سرویس</label>
                <input disabled={loading} ref={title} type='text' />
            </div>
        </Modal>
    )
}
