import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Link, redirect, useLoaderData } from 'react-router-dom'
import config from '../../Assets/js/config'
import { adminNavbar } from '../../Redux/admin'
import { useSelector } from 'react-redux'
import { Navbar } from '../../Components/Admin/Navbar'
import { Skeleton } from 'antd';
import { AddMemberModal } from '../../Components/Admin/AddMemberModal'

export const loader = async () => {
    const token = localStorage.getItem('@token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    try {
        const { data } = await axios.get(`${config.base}/auth/profile`, { headers })
        // const socialMedias = await axios.get(`${config.base}/auth/show-social-medias`, { headers })
        // const abilities = await axios.get(`${config.base}/auth/show-abilities`, { headers })
        if (!data.user || !token) {
            return redirect('/admin/login')
        }
        if (data.user.role === 'root') {
            return { user: data.user }
        } else {
            return redirect('/admin/panel')
        }
    } catch (e) {
        return redirect('/admin/login')
    }
    return null
}

export const AddMember = () => {
    const token = localStorage.getItem('@token')
    const data = useLoaderData()
    const [users, setUsers] = useState({ loading: true, data: [] })
    const [modalOpen, setModalOpen] = useState(false)

    const getUssers = async () => {
        const headers = {
            'Authorization': `Bearer ${token}`
        }
        try {
            setUsers({ loading: true, data: [] })
            const { data } = await axios.get(`${config.base}/users`, { headers })
            setUsers({ loading: false, data: [...data] })

        } catch (e) {
            console.error(e)
        }
    }

    const ar = [4, 5]
    

    useEffect(() => {
        getUssers()

    }, [])


    return (
        <div className='km-admin-panel'>

            <AddMemberModal open={modalOpen} setOpen={setModalOpen} getUsers={getUssers} />
            <nav>
                <div className='right'>
                    <img src={`${config.imageBase}/${data.user.profile}`} />
                    <h5>{data.user.name}</h5>
                </div>
                <div className='left'>
                    <Link to='/admin/logout'>
                        خروج از حساب کاربری
                    </Link>
                </div>
            </nav>

            <main>
                <Navbar role={data.user.role} />


                <div className='left'>
                    {users.loading ? <div style={{ marginTop: 20 }}><Skeleton active /></div> : <div className='books-container'>
                        <div className='books-head'>
                            <h1>اعضای آریوریتم</h1>
                            <div>
                                <input type='search' placeholder='Search content here...' />
                                <input type='button' value={'افزودن عضو'} onClick={() => setModalOpen(true)} />
                            </div>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>name</th>
                                    <th>mobile</th>
                                    <th>nickname</th>
                                    <th>role</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.data.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.mobile}</td>
                                        <td>{item.nickname}</td>
                                        <td>{item.role}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                    }

                </div>

            </main>

        </div>
    )
}
