import React from 'react'
import { Link } from 'react-router-dom'
import { motion, } from "framer-motion";
import config from '../../Assets/js/config';


export const Card = ({
    item,
}) => {

    return (
        <Link to={`/projects/${item.title.replaceAll(' ', '-')}`} className='km-project-card-container' key={item.id} >
            <motion.div
                className='km-project-card'
                key={item.id}
                initial={{ opacity: 0, }}
                animate={{ opacity: 1, }}
                exit={{ opacity: 0 }}
                transition={{ duration: .7 }}
            >
                <img src={config.imageBase + '/' + item.logo} />
                <h5>{item.title}</h5>
                <h5 className='km-project-card-category'>{item.platforms.map(item => item.title).join('-')}</h5>
            </motion.div>
        </Link>
    )
}
