import React from 'react'
import { Link } from 'react-router-dom'

export const Notfound = () => {
    return (
        <section class="text-center py-0">
            <div class="bg-holder overlay overlay-elixir" style={{ backgroundImage: process.env.PUBLIC_URL + "url(/Assets/img/background-14.jpg)" }}></div>
            <div class="container">
                <div class="row min-vh-100 align-items-center text-white">
                    <div class="col" data-zanim-timeline="{}" data-zanim-trigger="scroll">
                        <h5 class="text-uppercase mt-5 ls text-white fs-0 fs-md-1" data-zanim-xs='{"delay":0.2}'>oops! page not found</h5>
                        <h1 class="fs-4 fs-sm-6 fs-md-8 text-white" data-zanim-xs='{"delay":0.3}'>404</h1>
                        <div data-zanim-xs='{"delay":0.4}'>
                            <Link class="btn btn-lg btn-warning rounded-pill mt-4" to='/'>بازگشت به صفحه اصلی</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
