import React from 'react'
import { Container, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const Header = () => {
    const closeNav = () => {
        document.querySelector('#primaryNavbarCollapse').classList.remove('show')
    }

    return (
        <>
            <div className="bg-primary py-3 d-none d-sm-block text-white fw-bold">
                <div className="container">
                    <div className="row align-items-center gx-4">
                        <div className="col-auto d-none d-lg-block fs--1">
                            {/* <span
                                className="fas fa-map-marker-alt text-warning me-2"
                                data-fa-transform="grow-3"
                            ></span> */}
                            <svg className='mx-2' xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path fill='#fdd428' d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                            لاهیجان, میدان انقلاب, نبش فیاض۴, پاساژ ظروفی
                        </div>
                        <div
                            className="col-auto ms-md-auto order-md-2 d-none d-sm-flex fs--1 align-items-center"
                        >
                            {/* <span
                                className="fas fa-clock text-warning me-2"
                                data-fa-transform="grow-3"></span> */}
                            <svg className='mx-2' xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path fill='#fdd428' d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" /></svg>
                            شنبه تا پنجشنبه از ساعت ۸ صبح الی ۸ شب
                        </div>
                        <div className="col-auto">
                            {/* <span
                                className="fas fa-phone-alt text-warning"
                                data-fa-transform="shrink-3"
                            ></span> */}
                            <svg className='mx-2' xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path fill='#fdd428' d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                            <Link
                                className="ms-2 fs--1 d-inline text-white fw-bold"
                                to="tel:2123865575">۲۳۳۳ ۵۹۸ ۹۳۶ ۹۸+</Link>
                        </div>
                    </div>
                </div>
            </div>

            <Navbar collapseOnSelect expand="md" className="sticky-top navbar-elixir" >
                <Container className="container---">

                    <nav className="navbar navbar-expand-lg" style={{ width: '100%' }}>
                        {/* <Link className="navbar-brand" to="index.html">
                            <img src="Assets/img/logo-dark.png" alt="logo" />
                        </Link> */}
                        <Link className="navbar-brand" to="/">
                            <img
                                src={`${process.env.PUBLIC_URL}/Assets/img/logo-header.png`}
                                alt="logo"
                                loading='lazy'
                            />
                        </Link>
                        <button
                            className="navbar-toggler p-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#primaryNavbarCollapse"
                            aria-controls="primaryNavbarCollapse"
                            aria-expanded="false"
                            aria-label="Toggle navigation">

                            <span className="hamburger hamburger--emphatic">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </span>
                        </button>

                        <Navbar.Collapse className="collapse navbar-collapse" id="primaryNavbarCollapse">
                            <ul className="navbar-nav py-3 py-lg-0 mt-1 mb-2 my-lg-0 ms-lg-n1" aria-labelledby="primaryNavbarCollapse">
                                <li className="nav-item">
                                    <Link
                                        onClick={closeNav}
                                        className="nav-link"
                                        to="/">صفحه اصلی</Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        onClick={closeNav}
                                        className="nav-link"
                                        to="/services">خدمات</Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        onClick={closeNav}
                                        className="nav-link"
                                        to="/projects">نمونه کار ها</Link>
                                </li>

                                <li className="nav-item">
                                    <Link
                                        onClick={closeNav}
                                        className="nav-link"
                                        to="/about"
                                        role="button"
                                        aria-expanded="false">درباره ما</Link>
                                </li>
                                <li className="nav-item">
                                    <Link onClick={closeNav} className="nav-link" to="/team" role="button">تیم ما</Link>
                                </li>
                                <li className="nav-item">
                                    <Link onClick={closeNav} className="nav-link" to="/contactUs" role="button">تماس با ما</Link>
                                </li>
                            </ul>
                        </Navbar.Collapse>
                    </nav>
                </Container>
            </Navbar>
        </>
    )
}