import React from 'react'

export const ContactUs = () => {
    return (
        <section class="bg-primary">
            <div class="container">
                <div class="row align-items-center text-white">
                    <div class="col-lg-4">
                        <div
                            class="border border-2 border-warning p-4 py-lg-5 text-center rounded-3"
                            data-zanim-timeline="{}"
                            data-zanim-trigger="scroll"
                        >
                            <div class="overflow-hidden">
                                <h4 class="text-white" data-zanim-xs='{"delay":0}'>
                                    درخواست مشاوره رایگان
                                </h4>
                            </div>
                            <div class="overflow-hidden">
                                <p
                                    class="px-lg-1 text-100 mb-0"
                                    data-zanim-xs='{"delay":0.1}'
                                >
                                    اگر قصد دریافت مشاوره رایگان در زمینه پروژه های برنامه نویسی را دارید کافیست مشخصات خود را برای ما ارسال کنید تا  در اصرع وقت کارشناسان ما با شما تماس بگیرند.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 ps-lg-5 mt-6 mt-lg-0">
                        <h5 class="text-white">فرم تماس </h5>
                        <form class="mt-4" method="post">
                            <div class="row">
                                <div class="col-6">
                                    <input
                                        class="form-control"
                                        type="hidden"
                                        name="to"
                                        value="username@domain.extension"
                                    /><input
                                        class="form-control"
                                        type="text"
                                        placeholder="نام شما"
                                        aria-label="Your Name"
                                    />
                                </div>
                                <div class="col-6">
                                    <input
                                        class="form-control"
                                        type="text"
                                        placeholder="شماره تلفن"
                                        aria-label="Phone Number"
                                    />
                                </div>
                                <div class="col-6 mt-4">
                                    <input
                                        class="form-control"
                                        type="text"
                                        placeholder="پیام شما"
                                        aria-label="Subject"
                                    />
                                </div>
                                <div class="col-6 mt-4">
                                    <button class="btn btn-warning w-100" type="submit">
                                        ارسال
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}
