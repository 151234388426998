import React, { useEffect, useRef, useState } from 'react'
import config from '../../Assets/js/config'
import axios from 'axios'
import { Link, redirect, useLoaderData } from 'react-router-dom'
import { Navbar } from '../../Components/Admin/Navbar'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Skeleton } from 'antd'



export const loader = async ({ params }) => {
    const token = localStorage.getItem('@token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    try {
        const { data } = await axios.get(`${config.base}/auth/profile`, { headers })
        const project = await axios.post(`${config.base}/project`, { title: params.title }, { headers })

        if (!data.user || !token) {
            return redirect('/admin/login')
        }

        return { user: data, project: project.data }
    } catch (e) {
        alert('k')
        console.error(e)
        return redirect('/admin/login')
    }
    return null
}



export const EditProject = () => {
    const token = localStorage.getItem('@token')
    const headers = {
        'Authorization': `Bearer ${token}`,
        "Accept": "application/json",
        "Content-Type": "multipart/form-data",
    }
    const loaderdata = useLoaderData()

    const [data, setData] = useState({ ...loaderdata })

    const projectId = data.project.id
    const projectTitle = data.project.title
    const [users, setUsers] = useState({ loading: true, data: [] })
    const [technologies, setTechnologies] = useState({ loading: true, data: [] })
    const [platforms, setPlatforms] = useState({ loading: true, data: [] })

    const [projectUsers, setProjectUsers] = useState([])
    const [projectTechnologies, setProjectTechnologies] = useState([])
    const [projectPlatforms, setProjectPlatforms] = useState([])
    const [shortDescription, setShortDescription] = useState('')
    const [longDescription, setLongDescription] = useState('')


    const title = useRef()
    const link = useRef()
    const startDate = useRef()
    const endDate = useRef()
    const [image, setImage] = useState(null)
    const [imageShow, setImageShow] = useState(null)

    const [logo, setLogo] = useState(null)
    const [logoShow, setLogoShow] = useState(null)


    const getUsers = async () => {
        try {
            setUsers({ loading: true, data: [] })
            const { data } = await axios.get(`${config.base}/users`, { headers })
            setUsers({ loading: false, data: [...data] })
        } catch (e) {
            console.error(e)
            setUsers({ loading: false, data: [] })
        }
    }


    const getTechnologies = async () => {
        try {
            setTechnologies({ loading: true, data: [] })
            const { data } = await axios.get(`${config.base}/technologies`, { headers })
            setTechnologies({ loading: false, data: [...data] })
        } catch (e) {
            console.error(e)
            setTechnologies({ loading: false, data: [] })
        }
    }
    const getPlatforms = async () => {
        try {
            setPlatforms({ loading: true, data: [] })
            const { data } = await axios.get(`${config.base}/platforms`, { headers })
            setPlatforms({ loading: false, data: [...data] })
        } catch (e) {
            console.error(e)
            setPlatforms({ loading: false, data: [] })
        }
    }

    console.log(data)



    useEffect(() => {
        getUsers()
        getTechnologies()
        getPlatforms()
    }, [])

    useEffect(() => {
        title.current.value = data.project.title
        link.current.value = data.project.link
        startDate.current.value = data.project.startdate
        endDate.current.value = data.project.enddate
        setShortDescription(data.project.shortdescription)
        setLongDescription(data.project.longdescription)

        const ab = []
        data.project.users.forEach(item => {
            ab.push({
                ...item,
                elementId: (item.id + Math.random()) * 35,
                user_id: item.id,
            })
        });

        setProjectUsers([...ab])

        const so = []
        data.project.technologies.forEach(item => {
            so.push({
                id: item.pivot.technology_id,
                title: item.pivot.title,
                technology_id: item.pivot.technology_id,
                elementId: (item.id + Math.random()) * 35,
            })
        });

        setProjectTechnologies([...so])

        const pl = []
        data.project.platforms.forEach(item => {
            pl.push({
                id: item.pivot.platform_id,
                title: item.pivot.title,
                platform_id: item.pivot.platform_id,
                elementId: (item.id + Math.random()) * 35,
            })
        });

        setProjectPlatforms(pl)

        if (data.project.logo) {
            setLogoShow(config.imageBase + '/' + data.project.logo)
        }

    }, [loaderdata])


    const getProject = async () => {
        const token = localStorage.getItem('@token')
        const headers = {
            'Authorization': `Bearer ${token}`
        }
        try {
            const { data } = await axios.post(`${config.base}/project`, { title: projectTitle }, { headers })
            setData(prev => {
                return {
                    ...prev, project: data
                }
            })
        } catch (e) {
            console.error(e)
        }
    }



    const addImage = async () => {
        try {
            const { data } = await axios.post(`${config.base}/auth/update-project`, {
                projectId: projectId,
                image: image,
            }, { headers })
            getProject()
            setImage(null)
            setImageShow(null)
        } catch (e) {
            console.error(e)
        }
    }

    const addLogo = async () => {
        try {
            const { data } = await axios.post(`${config.base}/auth/update-project`, {
                projectId: projectId,
                logo,
            }, { headers })
            getProject()
            setLogo(null)
            setLogoShow(null)
        } catch (e) {
            console.error(e)
        }
    }



    const submitProject = async () => {
        try {
            const { data } = await axios.post(`${config.base}/auth/update-project`, {
                projectId: projectId,
                title: title.current.value,
                link: link.current.value,
                startDate: startDate.current.value,
                endDate: endDate.current.value,
                shortDescription,
                longDescription,
                users: projectUsers,
                technologies: projectTechnologies,
                platforms: projectPlatforms
            }, { headers })
            getProject()
            console.log(data)
        } catch (e) {
            console.error(e)
        }
    }



    return (
        <div className='km-admin-panel'>

            <nav>
                <div className='right'>
                    <img src={`${config.imageBase}/${data.user.user.profile}`} />
                    <h5>{data.user.user.name}</h5>
                </div>
                <div className='left'>
                    <Link to='/admin/logout'>
                        خروج از حساب کاربری
                    </Link>
                </div>
            </nav>

            <main>
                <Navbar role={data.user.user.role} />

                <div className='left'>
                    <h5>ویرایش حساب کاربری</h5>
                    <div className='update-profile-admin'>
                        <div className='km-km-row'>
                            <div>
                                <h5>نام پروژه</h5>
                                <input type='text' ref={title} />
                            </div>
                            <div>
                                <h5>لینک پروژه</h5>
                                <input type='text' ref={link} />
                            </div>
                            <div>
                                <h5>تاریخ شروع</h5>
                                <input type='text' ref={startDate} />
                            </div>
                            <div>
                                <h5>تاریخ پایان</h5>
                                <input type='text' ref={endDate} />
                            </div>
                        </div>

                        <div>
                            <h5>تصاویر</h5>
                            <div className='km-edit-project-gallery'>

                                {data.project.images.map(item => (
                                    <>
                                        <img loading='lazy' src={`${config.imageBase}/${item.path}`} width={200} height={200} />
                                    </>
                                ))}
                            </div>

                        </div>


                        <br />
                        <br />
                        <div>
                            <h5>آپلود تصویر</h5>

                            {imageShow && <label htmlFor='km-km-km-image'>
                                <img loading='lazy' src={imageShow} width={100} height={100} />
                            </label>}

                            {imageShow && <Button onClick={addImage}>اپلود</Button>}




                            <input hidden={image ? true : false} id='km-km-km-image' type='file' accept="image/*" onChange={e => {
                                const imageFile = e.target.files[0];
                                setImage(imageFile);
                                const reader = new FileReader();

                                reader.onload = (event) => {
                                    setImageShow(event.target.result)
                                };

                                reader.readAsDataURL(imageFile);
                            }} />
                        </div>

                        <br />
                        <br />


                        <h5>تصویر لوگو</h5>


                        {logoShow && <label htmlFor='km-km-km-image-logo'>
                            <img loading='lazy' src={logoShow} width={100} height={100} />
                        </label>}

                        {logo && <Button onClick={addLogo}>اپلود</Button>}


                        <input hidden={logo || logoShow ? true : false} id='km-km-km-image-logo' type='file' accept="image/*" onChange={e => {
                            const imageFile = e.target.files[0];
                            setLogo(imageFile);
                            const reader = new FileReader();

                            reader.onload = (event) => {
                                setLogoShow(event.target.result)
                            };

                            reader.readAsDataURL(imageFile);
                        }} />

                        <br />
                        <br />





                        <div className='km-kfgyjdvhgsjb-add-social-profile'>
                            <h5>اعضا</h5>

                            {projectUsers.map(item => (
                                <>
                                    {users.loading ? 'loading' :
                                        <select
                                            {...(item.id && { defaultValue: item.id })}
                                            onChange={(e) => {
                                                const abId = projectUsers.findIndex(element => element.elementId == item.elementId)
                                                const temp = [...projectUsers]
                                                temp[abId].user_id = e.target.value
                                                setProjectUsers(temp)
                                            }}>
                                            <option selected disabled>شرکت کننده را انتخاب کنید</option>
                                            {users.data.map(item => (
                                                <option value={item.id}>{item.name}</option>
                                            ))}
                                        </select>
                                    }
                                </>
                            ))}

                        </div>

                        <div className='add-new-social-profile'>
                            <Button onClick={() => {
                                setProjectUsers([...projectUsers, {
                                    user_id: '',
                                    elementId: Math.random() * 10 + Math.random() * 9356
                                }])
                            }}>افزودن عضو جدید به پروژه</Button>
                        </div>


                        <div className='km-kfgyjdvhgsjb-add-social-profile'>
                            <h5>تکنولوژی ها</h5>

                            {projectTechnologies.map(item => (
                                <>
                                    {technologies.loading ? 'loading' :
                                        <select
                                            className='km-admin-social-table'
                                            {...(item.technology_id && { defaultValue: item.technology_id })}
                                            onChange={(e) => {
                                                const abId = projectTechnologies.findIndex(element => element.elementId == item.elementId)
                                                const temp = [...projectTechnologies]
                                                temp[abId].technology_id = e.target.value
                                                setProjectTechnologies(temp)
                                            }}
                                        >
                                            <option selected disabled>تکنولوژی را انتخاب کنید</option>
                                            {technologies.data.map((item, index) => (
                                                <option value={item.id}>{item.title}</option>
                                            ))}
                                        </select>}
                                </>

                            ))}

                        </div>

                        <div className='add-new-social-profile'>
                            <Button onClick={() => {
                                setProjectTechnologies([...projectTechnologies, {
                                    technology_id: '',
                                    elementId: Math.random() * 10 + Math.random() * 9356
                                }])
                            }}>افزودن تکنولوژی جدید</Button>
                        </div>


                        <div className='km-kfgyjdvhgsjb-add-social-profile'>
                            <h5>پلتفرم ها</h5>

                            {projectPlatforms.map(item => (
                                <>
                                    {platforms.loading ? 'loading' :
                                        <select
                                            className='km-admin-social-table'
                                            {...(item.id && { defaultValue: item.id })}
                                            onChange={(e) => {
                                                const abId = projectPlatforms.findIndex(element => element.elementId == item.elementId)
                                                const temp = [...projectPlatforms]
                                                temp[abId].platform_id = e.target.value
                                                setProjectPlatforms(temp)
                                            }}
                                        >
                                            <option selected disabled>تکنولوژی را انتخاب کنید</option>
                                            {platforms.data.map((item, index) => (
                                                <option value={item.id}>{item.title}</option>
                                            ))}
                                        </select>}
                                </>

                            ))}

                        </div>

                        <div className='add-new-social-profile'>
                            <Button onClick={() => {
                                setProjectPlatforms([...projectPlatforms, {
                                    platform_id: '',
                                    elementId: Math.random() * 10 + Math.random() * 9356
                                }])
                            }}>افزودن پلتفرم جدید</Button>
                        </div>


                        <div>
                            <h5>توضیحات کوتاه</h5>
                            <CKEditor
                                config={{ language: 'fa', }}
                                {...(shortDescription && { data: shortDescription ? shortDescription : '' })}
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                    setShortDescription(editor.getData())
                                }}
                            />
                        </div>
                        <div>
                            <h5>توضیحات تکمیلی</h5>
                            <CKEditor
                                config={{ language: 'fa', }}
                                {...(longDescription && { data: longDescription })}
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                    setLongDescription(editor?.getData())
                                }}
                            />
                        </div>
                        <Button onClick={submitProject} className='km-update-profile-submit'>ثبت اطلاعات</Button>
                    </div>
                </div>

            </main>

        </div>
    )
}
