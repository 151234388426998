import axios from 'axios'
import React from 'react'
import config from '../../Assets/js/config'
import { redirect } from 'react-router-dom'

export const loader = async () => {
    const token = localStorage.getItem('@token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    try {
        const { data } = await axios.post(`${config.base}/auth/logout`, null, { headers })
        localStorage.removeItem('@token')
        return redirect('/admin/login')
    } catch (e) {
        console.error(e)
        localStorage.removeItem('@token')
        return redirect('/admin/login')
    }
    localStorage.removeItem('@token')
    return redirect('/admin/login')
}

export const Logout = () => {
    return (
        <div>Logout</div>
    )
}
