import React, { useEffect } from 'react'
import { Banner } from '../Components/Home/Banner'
import { Welcome } from '../Components/Welcome'
import { Services } from '../Components/Services'
import { WhyUs } from '../Components/Home/WhyUs'
import { FastContact } from '../Components/FastContact'
import { Technologies } from '../Components/Technologies'
import { ContactUs } from '../Components/ContactUs'
import { TeamSection } from '../Components/TeamSection'
import { Emblem } from '../Components/Emblem'
import { EmblemsSlider } from '../Components/Home/EmblemsSlider'
import { BrandsSection } from '../Components/Home/BrandsSection'
import { HomeNewsSection } from '../Components/Home/HomeNewsSection'
import withLoading from '../Assets/hooks/withLoading'
import { Helmet } from 'react-helmet'
import { useLoaderData, useLocation } from 'react-router-dom'
import axios from 'axios'
import config from '../Assets/js/config'
import { useSelector } from 'react-redux'
import { preloader, setPreloader } from '../Redux/configStore'
import { store } from '../Redux/store'

export const loader = async () => {
    try {
        store.dispatch(setPreloader(true))
        const { data } = await axios.get(`${config.base}/users`)
        const filtered = data.filter(item => item.role != 'root')
        store.dispatch(setPreloader(false))
        return filtered
    } catch (e) {
        console.error(e)
        throw 'something went wrong!'
    }
    return null
}

const Home = () => {
    const location = useLocation()
    const data = useLoaderData()

    useEffect(() => {
        window.scrollTo({ top: 1, behavior: 'smooth' })
    }, [location.pathname])


    return (
        <>
            <Helmet>
                <title>آریوریتم</title>
            </Helmet>
            <Banner />
            <Welcome />
            <Services />
            <WhyUs />
            <FastContact />
            <Technologies />
            <ContactUs />
            <TeamSection count={6} random={true} users={data} />
            <Emblem />
            {/* <EmblemsSlider /> */}
            <BrandsSection />
            {/* <HomeNewsSection /> */}
        </>
    )
}

export default withLoading(Home)