import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
    return (
        <>
            <section style={{ backgroundColor: "#3d4c6f" }}>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="bg-primary text-white p-5 p-lg-6 rounded-3">
                                <h4 class="text-white fs-1 fs-lg-2 mb-1">
                                    عضویت در خبرنامه آریو ریتم
                                </h4>
                                <p class="text-white">با عضویت در خبرنامه آریو ریتم از آخرین اخبار تکنولوژی با خبر شوید</p>
                                <form class="mt-4">
                                    <div class="row align-items-center">
                                        <div class="col-md-7 pe-md-0">
                                            <div class="input-group">
                                                <input
                                                    class="form-control"
                                                    type="email"
                                                    placeholder="ایمیل خود را وارد کنید"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-5 mt-3 mt-md-0">
                                            <div class="d-grid">
                                                <button class="btn btn-warning" type="submit">
                                                    <span class="text-primary fw-semi-bold">ارسال</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-lg-6 mt-4 mt-lg-0">
                            <div class="row">
                                <div class="col-6 col-lg-4 text-white ms-lg-auto">
                                    <ul class="list-unstyled">
                                        <li class="mb-3">
                                            <Link class="text-white" to="/contactUs">تماس با ما</Link>
                                        </li>
                                        {/* <li class="mb-3">
                                            <a class="text-white" href="index.html#!">پرسش های متداول</a>
                                        </li>
                                        <li class="mb-3">
                                            <a class="text-white" href="index.html#!">نمونه کارهای ما</a>
                                        </li>
                                        <li class="mb-3">
                                            <a class="text-white" href="index.html#!">وبلاگ</a>                  </li>
                                        <li class="mb-3">
                                            <a class="text-white" href="index.html#!">درباره ما</a>
                                        </li>
                                        <li class="mb-3">
                                            <a class="text-white" href="index.html#!">خدمات ما</a>
                                        </li> */}
                                    </ul>
                                </div>
                                <div class="col-6 col-sm-5 ms-sm-auto">
                                    <ul class="list-unstyled">
                                        <li class="mb-3">
                                            <Link class="text-decoration-none d-flex align-items-center" target='_blank' to="https://www.linkedin.com/groups/12851316/">
                                                <span class="brand-icon me-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: "rgba(0, 0, 0, 1)" }}><circle cx="4.983" cy="5.009" r="2.188" fill='#fff'></circle><path fill='#fff' d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path></svg>
                                                </span>
                                                <h5 class="fs-0 text-white mb-0 d-inline-block">Linkedin</h5>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <footer class="footer bg-primary text-center py-4">
                <div class="container">
                    <div class="row align-items-center opacity-85 text-white">
                        <div class="col-sm-3 text-sm-start">
                            <a href="index.html"
                            ><img src={`${process.env.PUBLIC_URL}/Assets/img/logo-header-light.png`} alt="logo"
                                /></a>
                        </div>
                        <div class="col-sm-6 mt-3 mt-sm-0">
                            <p class="lh-lg mb-0 fw-semi-bold">
                                &copy; Copyright 2021 Ariyo Rithm Inc.
                            </p>
                        </div>
                        <div class="col text-sm-end mt-3 mt-sm-0">
                            <span class="fw-semi-bold">طراحی و تسعه توسط </span>
                            <a
                                class="text-white"
                                href="https://themewagon.com/"
                                target="_blank">آریو ریتم</a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
