import React from 'react'

export const Emblem = () => {
    return (
        <section>
            <div
                class="bg-holder overlay overlay-elixir"
                style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/img/background-15.jpg)` }}
            ></div>
            <div class="container">
                <div class="d-flex">
                    <span class="me-3">
                        <img
                            src={`${process.env.PUBLIC_URL}/Assets/img/checkmark.png`}
                            alt="checkmark"
                            style={{ width: "55px" }}
                        /></span>
                    <div class="flex-1">
                        <h2 class="text-warning fs-3 fs-lg-4">
                            قدم درست بردارید,<br /><span class="text-white"
                            >کارهای بزرگ را انجام دهید
                            </span>
                        </h2>
                        <div class="row mt-4 pe-lg-10">
                            <div
                                class="overflow-hidden col-md-3"
                                data-zanim-timeline="{}"
                                data-zanim-trigger="scroll"
                            >
                                <div
                                    class="fs-3 fs-lg-4 mb-0 fw-bold text-white mt-lg-5 mt-3 lh-xs"
                                    data-zanim-xs='{"delay":0.1}'
                                    data-countup='{"endValue":52}'
                                >
                                    ۵۲
                                </div>
                                <h6 class="fs-0 text-white" data-zanim-xs='{"delay":0.2}'>
                                    پروژه های انجام شده
                                </h6>
                            </div>
                            <div
                                class="overflow-hidden col col-lg-3"
                                data-zanim-timeline="{}"
                                data-zanim-trigger="scroll"
                            >
                                <div
                                    class="fs-3 fs-lg-4 mb-0 fw-bold text-white mt-lg-5 mt-3 lh-xs"
                                    data-zanim-xs='{"delay":0.1}'
                                    data-countup='{"endValue":164}'
                                >
                                    ۱۶۴
                                </div>
                                <h6 class="fs-0 text-white" data-zanim-xs='{"delay":0.2}'>
                                    پروژه های در دست انجام
                                </h6>
                            </div>
                            <div class="w-100 d-flex d-lg-none"></div>
                            <div
                                class="overflow-hidden col-md-3"
                                data-zanim-timeline="{}"
                                data-zanim-trigger="scroll"
                            >
                                <div
                                    class="fs-3 fs-lg-4 mb-0 fw-bold text-white mt-lg-5 mt-3 lh-xs"
                                    data-zanim-xs='{"delay":0.1}'
                                    data-countup='{"endValue":38}'
                                >
                                    ۳۸
                                </div>
                                <h6 class="fs-0 text-white" data-zanim-xs='{"delay":0.2}'>
                                    اعضای فعال برنامه نویس
                                </h6>
                            </div>
                            <div
                                class="overflow-hidden col col-lg-3"
                                data-zanim-timeline="{}"
                                data-zanim-trigger="scroll"
                            >
                                <div
                                    class="fs-3 fs-lg-4 mb-0 fw-bold text-white mt-lg-5 mt-3 lh-xs"
                                    data-zanim-xs='{"delay":0.1}'
                                    data-countup='{"endValue":100}'
                                >
                                    ۱۰۰
                                </div>
                                <h6 class="fs-0 text-white" data-zanim-xs='{"delay":0.2}'>
                                    دوره برگزار شده
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
