import React, { useEffect, useRef, useState } from 'react'
import config from '../../Assets/js/config'
import axios from 'axios'
import { Link, redirect, useLoaderData } from 'react-router-dom'
import { Navbar } from '../../Components/Admin/Navbar'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Skeleton } from 'antd'



export const loader = async ({ params }) => {
    const token = localStorage.getItem('@token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    try {
        const { data } = await axios.get(`${config.base}/auth/profile`, { headers })
        const service = await axios.post(`${config.base}/service`, { title: params.title }, { headers })

        if (!data.user || !token) {
            return redirect('/admin/login')
        }

        return { user: data, service: service.data }
    } catch (e) {
        alert('k')
        console.error(e)
        return redirect('/admin/login')
    }
    return null
}



export const EditService = () => {
    const token = localStorage.getItem('@token')
    const headers = {
        'Authorization': `Bearer ${token}`,
        "Accept": "application/json",
        "Content-Type": "multipart/form-data",
    }
    const loaderdata = useLoaderData()

    const [data, setData] = useState({ ...loaderdata })

    const serviceID = data.service.id
    const serviceTitle = data.service.title


    const [technologies, setTechnologies] = useState({ loading: true, data: [] })
    const [platforms, setProjects] = useState({ loading: true, data: [] })

    const [serviceTechnologies, setserviceTechnologies] = useState([])
    const [serviceProjects, setserviceProjects] = useState([])
    const [shortDescription, setShortDescription] = useState('')
    const [longDescription, setLongDescription] = useState('')


    const title = useRef()
    const link = useRef()
    const startDate = useRef()
    const endDate = useRef()
    const [image, setImage] = useState(null)
    const [imageShow, setImageShow] = useState(null)

    const [logo, setLogo] = useState(null)
    const [logoShow, setLogoShow] = useState(null)




    const getTechnologies = async () => {
        try {
            setTechnologies({ loading: true, data: [] })
            const { data } = await axios.get(`${config.base}/technologies`, { headers })
            setTechnologies({ loading: false, data: [...data] })
        } catch (e) {
            console.error(e)
            setTechnologies({ loading: false, data: [] })
        }
    }

    const getProjects = async () => {
        try {
            setProjects({ loading: true, data: [] })
            const { data } = await axios.get(`${config.base}/projects`, { headers })
            setProjects({ loading: false, data: [...data] })
        } catch (e) {
            console.error(e)
            setProjects({ loading: false, data: [] })
        }
    }


    const getService = async () => {
        const token = localStorage.getItem('@token')
        const headers = {
            'Authorization': `Bearer ${token}`
        }
        try {
            const { data } = await axios.post(`${config.base}/service`, { title: serviceTitle }, { headers })
            setData(prev => {
                return { ...prev, service: data }
            })
        } catch (e) {
            alert('k')
            console.error(e)
            return redirect('/admin/login')
        }
    }


    useEffect(() => {
        getTechnologies()
        getProjects()
    }, [])

    useEffect(() => {
        title.current.value = data.service.title
        setShortDescription(data.service.shortDescription)
        setLongDescription(data.service.longDescription)

        const so = []
        data.service.technologies.forEach(item => {
            so.push({
                id: item.pivot.technology_id,
                title: item.pivot.title,
                technology_id: item.pivot.technology_id,
                elementId: (item.id + Math.random()) * 35,
            })
        });

        setserviceTechnologies([...so])

        const pl = []
        data.service.projects.forEach(item => {
            pl.push({
                id: item.pivot.project_id,
                project_id: item.pivot.project_id,
                elementId: (item.id + Math.random()) * 35,
            })
        });

        setserviceProjects(pl)

        if (data.service.profileImage) {
            setImageShow(config.imageBase + '/' + data.service.profileImage)
        }
        if (data.service.mainImage) {
            setLogoShow(config.imageBase + '/' + data.service.mainImage)
        }

    }, [loaderdata])






    console.log(data.service)









    const submitService = async () => {
        try {
            const { data } = await axios.post(`${config.base}/auth/update-service`, {
                // projectId: projectId,
                serviceID,
                title: title.current.value,
                shortDescription,
                longDescription,
                technologies: serviceTechnologies,
                projects: serviceProjects
            }, { headers })
            getService()
        } catch (e) {
            console.error(e)
        }
    }





    const addImage = async () => {
        try {
            const { data } = await axios.post(`${config.base}/auth/update-service`, {
                serviceID,
                profileImage: image,
            }, { headers })
            setImage(null)
            setImageShow(null)
            getService()
        } catch (e) {
            console.error(e)
        }
    }

    const addLogo = async () => {
        try {
            const { data } = await axios.post(`${config.base}/auth/update-service`, {
                serviceID,
                mainImage: logo,
            }, { headers })
            setLogo(null)
            setLogoShow(null)
            getService()
        } catch (e) {
            console.error(e)
        }
    }




    return (
        <div className='km-admin-panel'>

            <nav>
                <div className='right'>
                    <img src={`${config.imageBase}/${data.user.user.profile}`} />
                    <h5>{data.user.user.name}</h5>
                </div>
                <div className='left'>
                    <Link to='/admin/logout'>
                        خروج از حساب کاربری
                    </Link>
                </div>
            </nav>

            <main>
                <Navbar role={data.user.user.role} />

                <div className='left'>
                    <h5>ویرایش خدمات</h5>
                    <div className='update-profile-admin'>
                        <div className='km-km-row'>
                            <div>
                                <h5>نام خدمات</h5>
                                <input type='text' ref={title} />
                            </div>
                        </div>



                        <br />
                        <br />
                        <div>
                            <h5>آپلود تصویر شاخص</h5>

                            {imageShow && <label htmlFor='km-km-km-image'>
                                <img loading='lazy' src={imageShow} width={100} height={100} />
                            </label>}

                            {image && <Button onClick={addImage}>اپلود</Button>}




                            <input hidden={imageShow ? true : false} id='km-km-km-image' type='file' accept="image/*" onChange={e => {
                                const imageFile = e.target.files[0];
                                setImage(imageFile);
                                const reader = new FileReader();

                                reader.onload = (event) => {
                                    setImageShow(event.target.result)
                                };

                                reader.readAsDataURL(imageFile);
                            }} />
                        </div>
 
                        <br />
                        <br />


                        <h5>تصویر ثانویه</h5>


                        {logoShow && <label htmlFor='km-km-km-image-logo'>
                            <img loading='lazy' src={logoShow} width={100} height={100} />
                        </label>}

                        {logo && <Button onClick={addLogo}>اپلود</Button>}


                        <input hidden={logo || logoShow ? true : false} id='km-km-km-image-logo' type='file' accept="image/*" onChange={e => {
                            const imageFile = e.target.files[0];
                            setLogo(imageFile);
                            const reader = new FileReader();

                            reader.onload = (event) => {
                                setLogoShow(event.target.result)
                            };

                            reader.readAsDataURL(imageFile);
                        }} />

                        <br />
                        <br />




                        <div className='km-kfgyjdvhgsjb-add-social-profile'>
                            <h5>تکنولوژی ها</h5>

                            {serviceTechnologies.map(item => (
                                <>
                                    {technologies.loading ? 'loading' :
                                        <select
                                            className='km-admin-social-table'
                                            {...(item.technology_id && { defaultValue: item.technology_id })}
                                            onChange={(e) => {
                                                const abId = serviceTechnologies.findIndex(element => element.elementId == item.elementId)
                                                const temp = [...serviceTechnologies]
                                                temp[abId].technology_id = e.target.value
                                                setserviceTechnologies(temp)
                                            }}
                                        >
                                            <option selected disabled>تکنولوژی را انتخاب کنید</option>
                                            {technologies.data.map((item, index) => (
                                                <option value={item.id}>{item.title}</option>
                                            ))}
                                        </select>}
                                </>

                            ))}

                        </div>

                        <div className='add-new-social-profile'>
                            <Button onClick={() => {
                                setserviceTechnologies([...serviceTechnologies, {
                                    technology_id: '',
                                    elementId: Math.random() * 10 + Math.random() * 9356
                                }])
                            }}>افزودن تکنولوژی جدید</Button>
                        </div>


                        <div className='km-kfgyjdvhgsjb-add-social-profile'>
                            <h5>پروژه ها</h5>

                            {serviceProjects.map(item => (
                                <>
                                    {platforms.loading ? 'loading' :
                                        <select
                                            className='km-admin-social-table'
                                            {...(item.id && { defaultValue: item.id })}
                                            onChange={(e) => {
                                                const abId = serviceProjects.findIndex(element => element.elementId == item.elementId)
                                                const temp = [...serviceProjects]
                                                temp[abId].project_id = e.target.value
                                                setserviceProjects(temp)
                                            }}
                                        >
                                            <option selected disabled>تکنولوژی را انتخاب کنید</option>
                                            {platforms.data.map((item, index) => (
                                                <option value={item.id}>{item.title}</option>
                                            ))}
                                        </select>}
                                </>

                            ))}

                        </div>

                        <div className='add-new-social-profile'>
                            <Button onClick={() => {
                                setserviceProjects([...serviceProjects, {
                                    project_id: '',
                                    elementId: Math.random() * 10 + Math.random() * 9356
                                }])
                            }}>افزودن پروژه جدید</Button>
                        </div>


                        <div>
                            <h5>توضیحات کوتاه</h5>
                            <CKEditor
                                config={{ language: 'fa', }}
                                {...(shortDescription && { data: shortDescription ? shortDescription : '' })}
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                    setShortDescription(editor.getData())
                                }}
                            />
                        </div>
                        <div>
                            <h5>توضیحات تکمیلی</h5>
                            <CKEditor
                                config={{ language: 'fa', }}
                                {...(longDescription && { data: longDescription })}
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                    setLongDescription(editor?.getData())
                                }}
                            />
                        </div>
                        <Button onClick={submitService} className='km-update-profile-submit'>ثبت اطلاعات</Button>
                    </div>
                </div>

            </main>

        </div>
    )
}
