import React from 'react'
import { Link } from 'react-router-dom'

export const Banner = () => {
    return (
        <section>
            <div class="bg-holder overlay" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/img/background-2.jpg)`, backgroundPosition: "center bottom" }}></div>
            <div class="container">
                <div class="row pt-6" data-inertia='{"weight":1.5}'>
                    <div class="col-md-8 text-white" data-zanim-timeline="{}" data-zanim-trigger="scroll">
                        <div class="overflow-hidden">
                            <h1 class="text-white fs-4 fs-md-5 mb-0 lh-1" data-zanim-xs='{"delay":0}'>تماس با ما</h1>
                            <div class="nav" aria-label="breadcrumb" role="navigation" data-zanim-xs='{"delay":0.1}'>
                                <ol class="breadcrumb fs-1 ps-0 fw-bold">
                                    <li class="breadcrumb-item"><Link class="text-white" to="/contactUs">خانه</Link></li>
                                    <li class="breadcrumb-item active" aria-current="page">تماس با ما</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
