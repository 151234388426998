import React from 'react'

export const BrandsSection = () => {
    return (

        <div class="bg-200 py-6">
            <div class="container">
                <div
                    class="row align-items-center"
                    data-zanim-timeline="{}"
                    data-zanim-trigger="scroll"
                >

                    {/* <div class="col-4 col-md-2 my-3 overflow-hidden">
                        <img
                            class="img-fluid"
                            src={`${process.env.PUBLIC_URL}/Assets/img/partner/logo2.png`}
                            alt="partnerco"
                            data-zanim-xs="{}"
                        />
                    </div>
                    <div class="col-4 col-md-2 my-3 overflow-hidden">
                        <img
                            class="img-fluid"
                            src={`${process.env.PUBLIC_URL}/Assets/img/partner/logo1.png`}
                            alt="tvc"
                            data-zanim-xs="{}"
                        />
                    </div>
                    <div class="col-4 col-md-2 my-3 overflow-hidden">
                        <img
                            class="img-fluid"
                            src={`${process.env.PUBLIC_URL}/Assets/img/partner/logo6.png`}
                            alt="arcade"
                            data-zanim-xs="{}"
                        />
                    </div>
                    <div class="col-4 col-md-2 my-3 overflow-hidden">
                        <img
                            class="img-fluid"
                            src={`${process.env.PUBLIC_URL}/Assets/img/partner/logo3.png`}
                            alt="bearbrand"
                            data-zanim-xs="{}"
                        />
                    </div>
                    <div class="col-4 col-md-2 my-3 overflow-hidden">
                        <img
                            class="img-fluid"
                            src={`${process.env.PUBLIC_URL}/Assets/img/partner/logo5.png`}
                            alt="fast brothers"
                            data-zanim-xs="{}"
                        />
                    </div>
                    <div class="col-4 col-md-2 my-3 overflow-hidden">
                        <img
                            class="img-fluid"
                            src={`${process.env.PUBLIC_URL}/Assets/img/partner/logo4.png`}
                            alt="harculis beards"
                            data-zanim-xs="{}"
                        />
                    </div> */}
                </div>
            </div>
        </div>
    )
}
