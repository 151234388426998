import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Link, redirect, useLoaderData } from 'react-router-dom'
import { Navbar } from '../../Components/Admin/Navbar'
import config from '../../Assets/js/config'
import { Skeleton } from 'antd'


export const loader = async () => {
    const token = localStorage.getItem('@token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    try {
        const { data } = await axios.get(`${config.base}/auth/profile`, { headers })
        // const socialMedias = await axios.get(`${config.base}/auth/show-social-medias`, { headers })
        // const abilities = await axios.get(`${config.base}/auth/show-abilities`, { headers })
        if (!data.user || !token) {
            return redirect('/admin/login')
        }
        if (data.user.role === 'root') {
            return { user: data.user }
        } else {
            return redirect('/admin/panel')
        }
    } catch (e) {
        alert('k')
        console.error(e)
        return redirect('/admin/login')
    }
    return null
}

export const AddSocial = () => {
    const data = useLoaderData()
    const title = useRef()
    const [loading, setLoading] = useState(false)
    const [socialMedias, setSocialMedias] = useState({ loading: true, data: [] })
    const token = localStorage.getItem('@token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    const addSocial = async () => {
        if (title?.current.value && !loading) {
            try {
                setLoading(true)
                const { data } = await axios.post(`${config.base}/auth/add-social-media`, {
                    title: title.current.value
                }, { headers })
                title.current.value = ''
                getSocialMedias()
                setLoading(false)
            } catch (e) {
                console.error(e)
                setLoading(false)
            }
        } else {
            alert('enter title')
        }
    }


    const getSocialMedias = async () => {
        try {
            setSocialMedias({ loading: true, data: [] })
            const { data } = await axios.get(`${config.base}/auth/show-social-medias`, { headers })
            setSocialMedias({ loading: false, data: [...data.socialMedias] })
        } catch (e) {
            console.error(e)
            setSocialMedias({ loading: false, data: [] })
        }
    }

    useEffect(() => {
        getSocialMedias()
    }, [])

    return (
        <div className='km-admin-panel'>

            <nav>
                <div className='right'>
                    <img src={`${config.imageBase}/${data.user.profile}`} />
                    <h5>{data.user.name}</h5>
                </div>
                <div className='left'>
                    <Link to='/admin/logout'>
                        خروج از حساب کاربری
                    </Link>
                </div>
            </nav>

            <main>
                <Navbar role={data.user.role} />

                <div className='left'>
                    <h5>افزودن شبکه اجتماعی</h5>
                    <label style={{ fontSize: 22 }}>عنوان</label>
                    <br />
                    <input disabled={loading} type='text' ref={title} />
                    <input
                        disabled={loading}
                        type='button'
                        value='افزودن'
                        onClick={addSocial}
                    />

                    {socialMedias.loading ? <div style={{ marginTop: 20 }}>
                        <Skeleton active />
                    </div> : <table className='km-admin-social-table'>
                        <thead>
                            <tr>
                                <th>id</th>
                                <th>title</th>
                            </tr>
                        </thead>
                        <tbody>
                            {socialMedias.data.map(item => (
                                <tr>
                                    <td>{item.id}</td>
                                    <td>{item.title}</td>
                                </tr>
                            ))}
                        </tbody>

                    </table>}


                </div>

            </main>

        </div>
    )
}
