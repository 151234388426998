import React, { useEffect, useState } from 'react'
import withLoading from '../Assets/hooks/withLoading'
import { useSelector } from 'react-redux'
import { category, projects } from '../Redux/projects'
import { Card } from '../Components/Projects/Card'
import { AnimatePresence } from 'framer-motion'
import { useLoaderData, useLocation } from 'react-router-dom'
import { preloader, setPreloader } from '../Redux/configStore'
import axios from 'axios'
import config from '../Assets/js/config'
import { store } from '../Redux/store'
import { Select, Skeleton } from 'antd';


export const loader = async (data) => {
    const { params } = data
    // const member = store.getState().team.members.find(item => item.id == params.id)
    // return member
    try {
        store.dispatch(setPreloader(true))
        const { data } = await axios.get(`${config.base}/platforms`)
        store.dispatch(setPreloader(false))
        console.log(data)
        return { platforms: data }
    } catch (e) {
        console.error(e)
        throw 'something went wrong!'
    }
    return null
}


const Projects = () => {


    const loaderdata = useLoaderData()
    const [data, setData] = useState({ platforms: loaderdata.platforms, projects: [] })
    const [selectedCategory, setSelectedCategory] = useState('all')
    const [loading, setLoading] = useState(false)
    const location = useLocation()



    useEffect(() => {
        window.scrollTo({ top: 1, behavior: 'smooth' })
    }, [location.pathname])


    const getProjects = async (title) => {
        setSelectedCategory(title)
        if (title === 'all') {
            try {
                setLoading(true)
                const { data } = await axios.get(`${config.base}/projects`)
                setLoading(false)
                setData(prev => {
                    return { ...prev, projects: [...data] }
                })
            } catch (e) {
                setLoading(false)
                console.error(e)
                throw 'something went wrong!'
            }
        } else {
            try {
                setLoading(true)
                const { data } = await axios.post(`${config.base}/platform`, { title })
                setLoading(false)
                setData(prev => {
                    return { ...prev, projects: [...data.projects] }
                })
            } catch (e) {
                setLoading(false)
                console.error(e)
                throw 'something went wrong!'
            }

        }
    }

    useEffect(() => {
        getProjects('all')
    }, [])


    return (
        <section class="bg-100">

            <div class="container-fluid">
                <div class="row g-0">

                    <div class="col-lg-12 px-4 py-5 my-lg-0 bg-white mt-4 rounded d-flex align-items-center">
                        <div className='container'>

                            <div className='km-projects-header'>
                                <div className='right'>
                                    <h5>نمونه کار ها</h5>
                                    <p className='km-projects-desc'>نمایش نمونه کار های تیم آریوریتم به شما باعث افتخار ماست.</p>
                                </div>

                                <div className='km-category'>
                                    <div
                                        onClick={() => getProjects('all')}
                                        className={`${selectedCategory === 'all' && 'active'}`}
                                    >همه</div>
                                    {data.platforms.slice(0, 8).map(item => (
                                        <div key={item.id} onClick={() => getProjects(item.title)} className={`${selectedCategory === item.title && 'active'}`}>{item.title}</div>
                                    ))}
                                </div>

                                <div className='km-category-mob'>
                                    <Select
                                        className='km-category-mob-select'
                                        defaultValue="all"
                                        style={{
                                            width: 200,
                                        }}
                                        options={[
                                            {
                                                label: 'پلتفرم',
                                                options: [
                                                    {
                                                        label: 'همه',
                                                        value: 'all'
                                                    }
                                                    ,
                                                    ...data.platforms.map(item => {
                                                        return {
                                                            value: item.title,
                                                            label: item.title
                                                        }
                                                    })]
                                            },
                                        ]}

                                        onChange={(e) => getProjects(e)}
                                    />
                                </div>
                            </div>

                            <AnimatePresence>
                                <div
                                    class="col-lg-12 px-4 py-5 my-lg-0 bg-white mt-4 rounded d-flex align-items-center km-projects-container justify-content-center">
                                    {loading ?
                                        Array(3).fill(0).map(item => <Skeleton.Button shape={'square'} active={true} size={350}></Skeleton.Button>)
                                        : data.projects.map(item => {
                                            return (
                                                <Card
                                                    item={item}
                                                    key={item.id} />
                                            )
                                        })}
                                    {/* {data.projects.map(item => {
                                        return (
                                            <Card
                                                item={item}
                                                key={item.id} />
                                        )
                                    })} */}
                                </div>
                            </AnimatePresence>

                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default withLoading(Projects)