import React, { useRef } from 'react'
import { motion, } from "framer-motion";
import { Link } from 'react-router-dom';
import helper from '../Assets/js/helper'
import config from '../Assets/js/config';
import DOMPurify from 'dompurify';
export const MemberCard = ({ item, ref }) => {
    return (

        <motion.div className="col-sm-6 col-lg-4 mt-4" key={item.id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 2.1 }}
        >
            <Link className="km-member" to={`/member/${item.name.replaceAll(' ', '-')}`}>
                <div className="card h-100">
                    <div className='nik-name-container'>
                        <img
                            loading='lazy'
                            className="card-img-top"
                            src={`${config.imageBase}/${item.profile}`}
                            alt="alireza mirhashemi"
                        />
                        {item.nickname && <><div className='nik-name-before'></div>
                            <div className='nik-name'>{item.nickname}</div></>}

                    </div>
                    <div
                        className="card-body"
                        data-zanim-timeline="{}"
                        data-zanim-trigger="scroll"
                    >
                        <div className="overflow-hidden">
                            <h5 data-zanim-xs='{"delay":0}'>{item.name}</h5>
                        </div>
                        <div className="overflow-hidden">
                            <h6
                                className="fw-normal text-500"
                                data-zanim-xs='{"delay":0.1}'>
                                {item.career}
                            </h6>
                        </div>
                        <div className="overflow-hidden">
                            <p
                                className="py-3 mb-0 km-short-desc"
                                data-zanim-xs='{"delay":0.2}'
                                dangerouslySetInnerHTML={{ __html: helper.textLength(DOMPurify.sanitize(item.shortDescription), 180) }}
                            >
                                {/* {helper.textLength(item.shortDescription, 180)} */}
                            </p>
                        </div>
                    </div>
                </div>
            </Link>
        </motion.div>
    )
}
