import React from 'react'
import { Link } from 'react-router-dom'

export const Services = () => {
    return (
        <section class="bg-100">
            <div class="container">
                <div class="text-center mb-6">
                    <h3 class="fs-2 fs-md-3">خدمات ما</h3>
                    <hr
                        class="short"
                        data-zanim-xs='{"from":{"opacity":0,"width":0},"to":{"opacity":1,"width":"4.20873rem"},"duration":0.8}'
                        data-zanim-trigger="scroll"
                    />
                </div>
                <div class="row g-0 position-relative mb-4 mb-lg-0">
                    <div
                        class="col-lg-6 py-3 py-lg-0 mb-0 position-relative"
                        style={{ minHeight: "400px" }}
                    >
                        <div
                            class="bg-holder rounded-ts-lg rounded-te-lg rounded-lg-te-0"
                            style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/img/kamin.jpg)` }}
                        ></div>
                    </div>
                    <div
                        class="col-lg-6 px-lg-5 py-lg-6 p-4 my-lg-0 bg-white rounded-bs-lg rounded-lg-bs-0 rounded-be-lg rounded-lg-be-0 rounded-lg-te-lg"
                    >
                        <div class="elixir-caret d-none d-lg-block"></div>
                        <div class="d-flex align-items-center h-100">
                            <div data-zanim-timeline="{}" data-zanim-trigger="scroll">
                                <div class="overflow-hidden">
                                    <h5 data-zanim-xs='{"delay":0}'>
                                        طراحی و توسعه سایت و نرم افزارهای تحت وب
                                    </h5>
                                </div>
                                <div class="overflow-hidden">
                                    <p class="mt-3" data-zanim-xs='{"delay":0.1}'>
                                        طراحی وب سایت و نرم افزارهای تحت وب در آریو ریتم با تمامی دستگاه‌های مختلف مانند موبایل، تبلت، لپ تاپ و حتی تلویزیون‌‌های هوشمند سازگاری دارد. از ویژگی‌های وب سایت ها و نرم افزارهای تحت وب طراحی شده توسط تیم آریو ریتم می‌توان به گرافیک حرفه‌ای، امنیت بالا، سرعت لود بالا، عدم هنگ کردن و پشتیبانی همه روزه اشاره کرد.
                                    </p>
                                </div>
                                {/* <div class="overflow-hidden">
                                    <div data-zanim-xs='{"delay":0.2}'>
                                        <Link class="d-flex align-items-center" to="/"
                                        >اطلاعات بیشتر
                                            <div class="overflow-hidden ms-2">
                                                <span
                                                    class="d-inline-block learn-more"
                                                    data-zanim-xs='{"from":{"opacity":0,"x":-30},"to":{"opacity":1,"x":0},"delay":0.8}'
                                                >&xrarr;</span>
                                            </div>
                                        </Link>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-0 position-relative mb-4 mb-lg-0">
                    <div
                        class="col-lg-6 py-3 py-lg-0 mb-0 position-relative order-lg-2"
                        style={{ minHeight: "400px" }}
                    >
                        <div
                            class="bg-holder rounded-ts-lg rounded-te-lg rounded-lg-te-0 rounded-lg-ts-0"
                            style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/img/rezain.jpg)` }}
                        ></div>
                    </div>
                    <div
                        class="col-lg-6 px-lg-5 py-lg-6 p-4 my-lg-0 bg-white rounded-bs-lg rounded-lg-bs-0 rounded-be-lg rounded-lg-be-0"
                    >
                        <div class="elixir-caret d-none d-lg-block"></div>
                        <div class="d-flex align-items-center h-100">
                            <div data-zanim-timeline="{}" data-zanim-trigger="scroll">
                                <div class="overflow-hidden">
                                    <h5 data-zanim-xs='{"delay":0}'>
                                        توسعه انواع نرم افزارهای تحت سیستم عامل
                                    </h5>
                                </div>
                                <div class="overflow-hidden">
                                    <p class="mt-3" data-zanim-xs='{"delay":0.1}'>
                                        تیم آریو ریتم با تکیه بر آخرین تکنولوژی‌های روز برنامه نویسی اقدام به طراحی و توسعه انواع نرم افزار برای سیستم عامل های مختلف اعم از ios , android , windows , … با کاربردهای مختلفی همچون حسابداری، اتوماسیون اداری، انبارداری و ... کرده است.
                                    </p>
                                </div>
                                {/* <div class="overflow-hidden">
                                    <div data-zanim-xs='{"delay":0.2}'>
                                        <Link class="d-flex align-items-center" to={'/'}>اطلاعات بیشتر
                                            <div class="overflow-hidden ms-2">
                                                <span
                                                    class="d-inline-block learn-more"
                                                    data-zanim-xs='{"from":{"opacity":0,"x":-30},"to":{"opacity":1,"x":0},"delay":0.8}'
                                                >&xrarr;</span>
                                            </div>
                                        </Link>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-0 position-relative mb-4 mb-lg-0">
                    <div
                        class="col-lg-6 py-3 py-lg-0 mb-0 position-relative"
                        style={{ minHeight: "400px" }}
                    >
                        <div
                            class="bg-holder rounded-ts-lg rounded-te-lg rounded-lg-te-0 rounded-lg-ts-0 rounded-bs-0 rounded-lg-bs-lg"
                            style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/img/allin.jpg)` }}
                        ></div>
                    </div>
                    <div
                        class="col-lg-6 px-lg-5 py-lg-6 p-4 my-lg-0 bg-white rounded-bs-lg rounded-lg-bs-0 rounded-be-lg"
                    >
                        <div class="elixir-caret d-none d-lg-block"></div>
                        <div class="d-flex align-items-center h-100">
                            <div data-zanim-timeline="{}" data-zanim-trigger="scroll">
                                <div class="overflow-hidden">
                                    <h5 data-zanim-xs='{"delay":0}'>
                                        برنامه نویسی در زمینه هوش مصنوعی
                                    </h5>
                                </div>
                                <div class="overflow-hidden">
                                    <p class="mt-3" data-zanim-xs='{"delay":0.1}'>
                                        برنامه نویسی هوش مصنوعی در آریو ریتم فرایند طراحی و پیاده‌سازی الگوریتم‌ها و برنامه‌های کامپیوتری است که قادر است وظایف هوشمندانه را به آسانی انجام دهد. در این فرایند، برنامه‌نویسان از مجموعه‌ای از تکنیک‌ها، الگوریتم‌ها و روش‌های هوش مصنوعی استفاده می‌کنند تا مدل‌ها و سیستم‌هایی را تولید کنند که با تجزیه و تحلیل داده‌ها و محاسبه‌های هوشمندانه، به تصمیم‌گیری و یادگیری بپردازند.
                                    </p>
                                </div>
                                {/* <div class="overflow-hidden">
                                    <div data-zanim-xs='{"delay":0.2}'>
                                        <a class="d-flex align-items-center" href="index.html#!"
                                        >اطلاعات بیشتر
                                            <div class="overflow-hidden ms-2">
                                                <span
                                                    class="d-inline-block learn-more"
                                                    data-zanim-xs='{"from":{"opacity":0,"x":-30},"to":{"opacity":1,"x":0},"delay":0.8}'>&xrarr;</span>
                                            </div>
                                        </a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-7">
                    <div
                        class="col-sm-6 col-lg-4 px-4 px-sm-3 mb-4 mb-lg-0"
                        data-zanim-timeline="{}"
                        data-zanim-trigger="scroll"
                    >
                        <h5 data-zanim-xs='{"delay":0}'>
                            <svg className='mx-2' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: "rgba(0, 0, 0, 1)" }}><path d="M15.332 8.668a3.333 3.333 0 0 0 0-6.663H8.668a3.333 3.333 0 0 0 0 6.663 3.333 3.333 0 0 0 0 6.665 3.333 3.333 0 0 0 0 6.664A3.334 3.334 0 0 0 12 18.664V8.668h3.332z"></path><circle cx="15.332" cy="12" r="3.332"></circle></svg>
                            طراحی رابط کاربری جذاب و کاربر پسند
                        </h5>
                        <p class="mt-3 pe-3 pe-lg-5 mb-0" data-zanim-xs='{"delay":0.1}'>
                            رابط کاربری حرفه‌ای و جذاب می‌تواند بهبود قابل توجهی در نرخ تبدیل (Conversion Rate) داشته باشد.
                        </p>
                    </div>
                    <div
                        class="col-sm-6 col-lg-4 px-4 px-sm-3 mb-4 mb-lg-0"
                        data-zanim-timeline="{}"
                        data-zanim-trigger="scroll"
                    >
                        <h5 data-zanim-xs='{"delay":0}'>
                            <svg className='mx-2' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: "rgba(0, 0, 0, 1)" }}><path d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 19V7h16l.002 12H4z"></path><path d="M9.293 9.293 5.586 13l3.707 3.707 1.414-1.414L8.414 13l2.293-2.293zm5.414 0-1.414 1.414L15.586 13l-2.293 2.293 1.414 1.414L18.414 13z"></path></svg>
                            کدنویسی استاندارد
                        </h5>
                        <p class="mt-3 pe-3 pe-lg-5 mb-0" data-zanim-xs='{"delay":0.1}'>
                            کدنویسی تمیز باعث راحت تر شدن همکاری برروی پروژه ، صرفه جویی در وقت و انرژی برای بهبود ، به روزرسانی و نگهداری از کدهای پروژه می شود.
                        </p>
                    </div>
                    <div
                        class="col-sm-6 col-lg-4 px-4 px-sm-3 mb-4 mb-lg-0"
                        data-zanim-timeline="{}"
                        data-zanim-trigger="scroll"
                    >
                        <h5 data-zanim-xs='{"delay":0}'>
                            <svg className='mx-2' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: "rgba(0, 0, 0, 1)" }}><path d="M20.995 6.9a.998.998 0 0 0-.548-.795l-8-4a1 1 0 0 0-.895 0l-8 4a1.002 1.002 0 0 0-.547.795c-.011.107-.961 10.767 8.589 15.014a.987.987 0 0 0 .812 0c9.55-4.247 8.6-14.906 8.589-15.014zM12 19.897V12H5.51a15.473 15.473 0 0 1-.544-4.365L12 4.118V12h6.46c-.759 2.74-2.498 5.979-6.46 7.897z"></path></svg>
                            تست ، ارزیابی و امنیت پروژه ها
                        </h5>
                        <p class="mt-3 pe-3 pe-lg-5 mb-0" data-zanim-xs='{"delay":0.1}'>
                            در این فعالیت امنیت پروژه با انجام حملات طراحی شده بر روی سیستم‌ها و شبکه به منظور شناخت نقاط آسیب‌پذیر بررسی و ارزیابی می‌شود.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
