import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Link, redirect, useLoaderData } from 'react-router-dom'
import config from '../../Assets/js/config'
import { adminNavbar } from '../../Redux/admin'
import { useSelector } from 'react-redux'
import { Navbar } from '../../Components/Admin/Navbar'
import { Button, Skeleton } from 'antd';
import { AddMemberModal } from '../../Components/Admin/AddMemberModal'
import { AddServiceModal } from '../../Components/Admin/AddServiceModal'

export const loader = async () => {
    const token = localStorage.getItem('@token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    try {
        const { data } = await axios.get(`${config.base}/auth/profile`, { headers })
        // const socialMedias = await axios.get(`${config.base}/auth/show-social-medias`, { headers })
        // const abilities = await axios.get(`${config.base}/auth/show-abilities`, { headers })
        if (!data.user || !token) {
            return redirect('/admin/login')
        }
        if (data.user.role === 'root') {
            return { user: data.user }
        } else {
            return redirect('/admin/panel')
        }
    } catch (e) {
        alert('k')
        console.error(e)
        return redirect('/admin/login')
    }
    return null
}
export const AddService = () => {
    const token = localStorage.getItem('@token')
    const data = useLoaderData()
    const [services, setServices] = useState({ loading: true, data: [] })
    const [modalOpen, setModalOpen] = useState(false)

    const getServices = async () => {
        const headers = {
            'Authorization': `Bearer ${token}`
        }
        try {
            setServices({ loading: true, data: [] })
            const { data } = await axios.get(`${config.base}/services`, { headers })
            setServices({ loading: false, data: [...data] })

        } catch (e) {
            console.error(e)
        }
    }



    useEffect(() => {
        getServices()

    }, [])

    console.log(services)

    return (
        <div className='km-admin-panel'>

            <AddServiceModal open={modalOpen} setOpen={setModalOpen} getServices={getServices} />
            <nav>
                <div className='right'>
                    <img src={`${config.imageBase}/${data.user.profile}`} />
                    <h5>{data.user.name}</h5>
                </div>
                <div className='left'>
                    <Link to='/admin/logout'>
                        خروج از حساب کاربری
                    </Link>
                </div>
            </nav>

            <main>
                <Navbar role={data.user.role} />


                <div className='left'>
                    {services.loading ? <div style={{ marginTop: 20 }}><Skeleton active /></div> : <div className='books-container'>
                        <div className='books-head'>
                            <h1>اعضای آریوریتم</h1>
                            <div>
                                <input type='search' placeholder='Search content here...' />
                                <input type='button' value={'افزودن عضو'} onClick={() => setModalOpen(true)} />
                            </div>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>title</th>
                                    <th>description</th>
                                    <th>edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {services.data.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.title}</td>
                                        <td>{item.shortDescription}</td>
                                        <td><Link to={'/admin/panel/editservice/' + item.title}>edit</Link></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                    }

                </div>

            </main>

        </div>
    )
}
