import React, { useState } from 'react'
import { store } from '../Redux/store'
import axios from 'axios'
import config from '../Assets/js/config'
import { setPreloader } from '../Redux/configStore'
import { Link, useLoaderData } from 'react-router-dom'
import DOMPurify from 'dompurify'
import helper from '../Assets/js/helper'


export const loader = async (data) => {
    const { params } = data
    // const member = store.getState().team.members.find(item => item.id == params.id)
    // return member
    try {
        store.dispatch(setPreloader(true))
        const { data } = await axios.get(`${config.base}/services`)
        store.dispatch(setPreloader(false))
        return { services: data }
    } catch (e) {
        console.error(e)
        throw 'something went wrong!'
    }
    return null
}


export const Services = () => {
    const data = useLoaderData()
    let flag = false

    return (
        <section class="bg-100">
            <div class="container">
                <div class="text-center mb-6">
                    <h3 class="fs-2 fs-md-3">خدمات ما</h3>
                    <hr
                        class="short"
                        data-zanim-xs='{"from":{"opacity":0,"width":0},"to":{"opacity":1,"width":"4.20873rem"},"duration":0.8}'
                        data-zanim-trigger="scroll"
                    />
                </div>
                {data.services.map((item, index) => {
                    flag = !flag
                    if (flag) {
                        return (
                            <Link to={`/service/${item.title.replaceAll(' ', '-')}`} class="row g-0 position-relative mb-4 mb-lg-0">
                                <div
                                    class="col-lg-6 py-3 py-lg-0 mb-0 position-relative"
                                    style={{ minHeight: "400px" }}
                                >
                                    <div
                                        class="bg-holder rounded-ts-lg rounded-te-lg rounded-lg-te-0"
                                        style={{ backgroundImage: `url(${config.imageBase}/${item.profileImage})` }}
                                    ></div>
                                </div>
                                <div
                                    class="col-lg-6 px-lg-5 py-lg-6 p-4 my-lg-0 bg-white rounded-bs-lg rounded-lg-bs-0 rounded-be-lg rounded-lg-be-0 rounded-lg-te-lg"
                                >
                                    <div class="elixir-caret d-none d-lg-block"></div>
                                    <div class="d-flex align-items-center h-100">
                                        <div data-zanim-timeline="{}" data-zanim-trigger="scroll">
                                            <div class="overflow-hidden">
                                                <h5 data-zanim-xs='{"delay":0}'>
                                                    {item.title}
                                                </h5>
                                            </div>
                                            <div class="overflow-hidden">
                                                <p class="mt-3" data-zanim-xs='{"delay":0.1}'
                                                    dangerouslySetInnerHTML={{ __html: helper.textLength(DOMPurify.sanitize(item.shortDescription), 200) }}></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )
                    } else {
                        return (
                            <Link to={`/service/${item.title.replaceAll(' ', '-')}`} class="row g-0 position-relative mb-4 mb-lg-0">
                                <div
                                    class="col-lg-6 py-3 py-lg-0 mb-0 position-relative order-lg-2"
                                    style={{ minHeight: "400px" }}
                                >
                                    <div
                                        class="bg-holder rounded-ts-lg rounded-te-lg rounded-lg-te-0 rounded-lg-ts-0"
                                        style={{ backgroundImage: `url(${config.imageBase}/${item.profileImage})` }}
                                    ></div>
                                </div>
                                <div
                                    class="col-lg-6 px-lg-5 py-lg-6 p-4 my-lg-0 bg-white rounded-bs-lg rounded-lg-bs-0 rounded-be-lg rounded-lg-be-0"
                                >
                                    <div class="elixir-caret d-none d-lg-block"></div>
                                    <div class="d-flex align-items-center h-100">
                                        <div data-zanim-timeline="{}" data-zanim-trigger="scroll">
                                            <div class="overflow-hidden">
                                                <h5 data-zanim-xs='{"delay":0}'>
                                                    {item.title}
                                                </h5>
                                            </div>
                                            <div class="overflow-hidden">
                                                <p class="mt-3" data-zanim-xs='{"delay":0.1}'
                                                    dangerouslySetInnerHTML={{ __html: helper.textLength(DOMPurify.sanitize(item.shortDescription), 180) }}></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )
                    }
                })}
            </div>
        </section>
    )
}
