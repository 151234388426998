import React, { useEffect } from 'react'
import { Banner } from '../Components/About/Banner'
import { TeamSection } from '../Components/TeamSection'
import withLoading from '../Assets/hooks/withLoading'
import { Helmet } from 'react-helmet'
import { useLoaderData, useLocation } from 'react-router-dom'
import axios from 'axios'
import config from '../Assets/js/config'
import { store } from '../Redux/store'
import { setPreloader } from '../Redux/configStore'

export const loader = async () => {
    try {
        store.dispatch(setPreloader(true))
        const { data } = await axios.get(`${config.base}/users`)
        const filtered = data.filter(item => item.role != 'root')
        store.dispatch(setPreloader(false))
        return filtered
    } catch (e) {
        console.error(e)
        throw 'something went wrong!'
    }
    return null
}

const Team = () => {
    const location = useLocation()
    const data = useLoaderData()

    useEffect(() => {
        window.scrollTo({ top: 1, behavior: 'smooth' })
    }, [location.pathname])

    return (
        <>
            <Helmet>
                <title>تیم ما</title>
            </Helmet>
            <Banner title={'تیم ما'} image={'Assets/img/slider-3.jpg'} />
            <TeamSection users={data} />
        </>
    )
}


export default withLoading(Team)