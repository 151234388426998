import { useEffect } from "react";
import { Footer } from "./Components/Footer";
import { Header } from "./Components/Header";
import { Outlet, useLocation } from "react-router-dom";
import { Toaster } from 'react-hot-toast';

function App() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 1, behavior: 'smooth' })
  }, [location.pathname])

  return (
    <>
      <Toaster />
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

export default App;
