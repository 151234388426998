import React, { useEffect } from 'react'
import { Link, useLoaderData, useLocation } from 'react-router-dom'
import withLoading from '../Assets/hooks/withLoading'
import { store } from '../Redux/store'
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import { Navigation, Autoplay, EffectFade } from 'swiper/modules';
import { setPreloader } from '../Redux/configStore';
import axios from 'axios';
import config from '../Assets/js/config';
import DOMPurify from 'dompurify';
import { Image, Tooltip } from 'antd';

export const loader = async ({ params }) => {
    // const { params } = data
    // store.dispatch(setPreloader(true))
    // const project = store.getState().projects.projects.find(item => item.engName == params.name)
    // store.dispatch(setPreloader(false))
    // return project
    try {
        store.dispatch(setPreloader(true))
        const { data } = await axios.post(`${config.base}/project`, { title: params.title.replaceAll('-', ' ').toLowerCase() })
        store.dispatch(setPreloader(false))
        console.log(data)
        return { project: data }
    } catch (e) {
        console.error(e)
        throw 'something went wrong!'
    }
    return null
}

const Project = () => {
    const data = useLoaderData()
    const location = useLocation()

    useEffect(() => {
        window.scrollTo({ top: 1, behavior: 'smooth' })
    }, [location.pathname])


    return (
        <section class="bg-100 km-project-page">
            <div class="container">
                <div class="row g-0 ">
                    <div
                        class="col-lg-4 py-3 py-lg-0 position-relative"
                        style={{ maxHeight: "400px", minHeight: "400px", backgroundPosition: "top" }}

                    >
                        <div
                            className="bg-holder km-personal-nik-name rounded km-personal-image"
                            style={{ backgroundImage: `url(${config.imageBase + '/' + data.project.logo})` }}
                        >
                        </div>

                    </div>
                    <div
                        class="col-lg-8 px-4 py-5 my-lg-0 bg-white rounded-lg-te-lg rounded-be-lg rounded-bs-lg rounded-lg-bs-0 d-flex align-items-center">
                        <div className='km-container-birth'>
                            <h5>{data.project.title}</h5>
                            <p
                                class="my-4 km-pers-desc"
                                data-zanim-xs='{"delay":0.1}'
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.project.shortdescription) }}
                            >

                            </p>

                            <Swiper
                                effect={'coverflow'}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={'auto'}
                                coverflowEffect={{
                                    rotate: 50,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 1,
                                    slideShadows: false,
                                }}
                                pagination={true}
                                modules={[EffectCoverflow, Pagination, Autoplay]}
                                autoplay={{
                                    delay: 2000,
                                    disableOnInteraction: false,
                                }}
                            >
                                {data.project.images.map(item => (
                                    <SwiperSlide>
                                        <Image src={config.imageBase + '/' + item.path} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            {data.project?.longdescription && <>
                                <h5 data-zanim-xs='{"delay":0}'>معرفی {data.project.title}</h5>
                                <p
                                    className='km-pers-desc'
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.project.longdescription) }}
                                ></p>
                            </>}

                            <h5 className='km-project-technologies-title-km'>پلتفرم ها</h5>
                            <ul>
                                {data.project.platforms.map(item => (
                                    <li>{item.title}</li>
                                ))}
                            </ul>

                            <h5 className='km-project-technologies-title-km' data-zanim-xs='{"delay":0}'>تکنولوژی های استفاده شده</h5>

                            <div className='km-project-technologies'>
                                <Swiper
                                    slidesPerView={5}
                                    spaceBetween={40}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay]}
                                    loop
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}

                                >
                                    {data.project.technologies.map(item => (
                                        <SwiperSlide>
                                            {/* {item.icon} */}
                                            <h6 className='km-project-km-title-tech'>{item.title}</h6>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>

                            <h5>توسعه دهندگان</h5>
                            <div className='km-project-users-km'>
                                {data.project.users.map(item => (
                                    <>
                                        <Tooltip placement="top" title={item.name}>
                                            <Link to={`/member/${item.name.replaceAll(' ', '-')}`} className='km-project-users-km-item'>
                                                <img loading='lazy' src={config.imageBase + '/' + item.profile} />
                                            </Link>
                                        </Tooltip>
                                    </>
                                ))}
                            </div>

                            <Link className='km-project-outer-link' to={data.project.link}>{data.project.link}</Link>

                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}


export default withLoading(Project)