import { createSlice } from '@reduxjs/toolkit'



export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        navbar: [
            {
                id: 1,
                title: 'افزودن عضو',
                to: '/admin/panel/addmember',
                type: 'root',
            },
            {
                id: 2,
                title: 'افزودن شبکه اجتماعی',
                to: '/admin/panel/addsocial',
                type: 'root',
            },
            {
                id: 3,
                title: 'افزودن مهارت',
                to: '/admin/panel/addability',
                type: 'root',
            },
            {
                id: 4,
                title: 'ویرایش پروفایل',
                to: '/admin/panel/editprofile',
                type: 'root',
            },
            {
                id: 5,
                title: 'ویرایش پروفایل',
                to: '/admin/panel/editprofile',
                type: 'member',
            },
            {
                id: 6,
                title: 'افزودن پلتفرم',
                to: '/admin/panel/addplatform',
                type: 'root',
            },
            {
                id: 7,
                title: 'افزودن تکنولوژی',
                to: '/admin/panel/addtechnology',
                type: 'root',
            },
            {
                id: 8,
                title: 'افزودن پروژه',
                to: '/admin/panel/addproject',
                type: 'root',
            },
            {
                id: 8,
                title: 'افزودن خدمات',
                to: '/admin/panel/addservice',
                type: 'root',
            },
        ]
    },
    reducers: {
    },
})

// Action creators are generated for each case reducer function
// export const { } = counterSlice.actions
export const adminNavbar = state => state.admin.navbar
export default adminSlice.reducer