import React from 'react'

export const Master = () => {
    return (
        <section class="bg-100">
            <div class="container">
                <div class="row mt-1">
                    <div class="col">
                        <h3 class="text-center fs-2 fs-md-3">درباره آریو ریتم</h3>
                        <hr
                            class="short"
                            data-zanim-xs='{"from":{"opacity":0,"width":0},"to":{"opacity":1,"width":"4.20873rem"},"duration":0.8}'
                            data-zanim-trigger="scroll"
                        />
                    </div>
                    <div class="col-12">
                        <div class="bg-white px-3 mt-6 px-0 py-5 px-lg-5 rounded-3">
                            <h5>داستان تولد یک تیم خلاق</h5>
                            <p class="mt-3">
                                در دنیای برنامه‌نویسی، توسعه‌دهندگان در زمینه های مختلفی تسلط دارند و برای اجرای پروژه‌های بزرگ و پیچیده نیاز است بین تخصص‌ها تعامل صورت گیرد. تیم‌های برنامه‌نویسی مختلفی وجود دارند که هر کدام در یک شاخه‌ی خاص تمرکز دارند، اما آیا تیمی وجود دارد که در همه‌ی شاخه‌های برنامه‌نویسی متخصص باشد؟
                            </p>
                            <blockquote
                                class="blockquote my-5 ms-lg-6"
                                style={{ maxWidth: "700px" }}
                            >
                                <h5 class="fw-medium ms-3 mb-0 km-about-h5">
                                    تیم برنامه‌نویسی آریوریتم یک تیم برنامه‌نویسی متخصص  است که در همه‌ی شاخه‌های برنامه‌نویسی فعال است.
                                </h5>
                            </blockquote>
                            <img
                                class="rounded-3 img-fluid"
                                src={process.env.PUBLIC_URL + '/Assets/img/team-about.jpg'}
                                data-zanim-xs='{"delay":0.2}'
                            />
                            <p className='py-5 km-this-team'>
                                این تیم برنامه‌نویسی اولین مرکز کارآموزی برنامه نویسی شرق گیلان و اولین مرکز کارآموزی آموزشی ایران می باشد.
                                در ادامه، به برخی از ویژگی‌ها و مزایای تیم برنامه‌نویسی آریوریتم پرداخته می‌شود:
                            </p>
                            <ol className='km-about-ol'>
                                <li>
                                    <b>تیم آریوریتم</b> تخصص‌های چندگانه‌ای را در برنامه‌نویسی ترکیب می‌کند. اعضای تیم از طریق آموزش‌های مداوم و تجربه کاری در زمینه‌های مختلف، تخصص‌های مورد نیاز را توسعه می‌دهند.
                                </li>
                                <li>
                                    <b>همکاری تیمی:</b> تیم آریوریتم به خوبی در همکاری تیمی عمل می‌کند. اعضای تیم از تجربه کاری در تیم‌های متنوع بهره می‌برند و توانایی همکاری بر روی پروژه‌های بزرگ و پیچیده را دارند.
                                </li>
                                <li>
                                    <b>انعطاف‌پذیری:</b> اعضای تیم آریوریتم انعطاف‌پذیری بالایی دارند و قادر به سازگاری با محیط‌های مختلف برنامه‌ پویایی و نیازمندی‌های پروژه می‌باشند. آن‌ها می‌توانند به سرعت به فناوری‌های نوظهور و تغییرات درخواست‌های مشتریان پاسخ دهند.
                                </li>
                                <li>
                                    <b>کیفیت و کارایی:</b> با ترکیب تخصص‌های مختلف در یک تیم، آریوریتم قادر به ارائه کیفیت بالا و کارایی بهتر در پروژه‌ها است. هر عضو تیم با تمرکز بر تخصص خود به بهبود عملکرد و کیفیت کد و ساختار پروژه کمک می‌کند.
                                </li>
                                <li>
                                    <b>انتقال دانش:</b> یکی از مزایای تیم برنامه‌نویسی آریوریتم، انتقال دانش بین اعضا است. اعضا می‌توانند از تجربیات و دانش خود در زمینه‌های مختلف بهره‌برداری کنند و این انتقال دانش می‌تواند به بهبود عملکرد و رشد تیم کمک کند.
                                </li>
                                <li>
                                    <b>نوآوری:</b> ترکیب تخصص‌های مختلف در تیم آریوریتم امکان نوآوری و ایده‌پردازی در پروژه‌ها را فراهم می‌کند. اعضا می‌توانند با ترکیب ایده‌ها و روش‌های مختلف، راهکارهای نوینی را ارائه دهند و بهبود‌های مستمر را در پروژه‌ها اعمال کنند.
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row g-0">
                    <div
                        class="col-lg-4 py-3 py-lg-0 position-relative"
                        style={{ minHeight: "400px", backgroundPosition: "top" }}
                    >
                        <div
                            class="bg-holder km-personal-nik-name rounded-ts-lg rounded-lg-bs-lg rounded-te-lg rounded-lg-te-0"
                            style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/img/kamransaberi.jpg)` }}
                        >
                            <div className='personal-nik-name-t'>
                                Big Boss
                            </div>
                        </div>

                    </div>
                    <div
                        class="col-lg-8 px-5 py-6 my-lg-0 bg-white rounded-lg-te-lg rounded-be-lg rounded-bs-lg rounded-lg-bs-0 d-flex align-items-center"
                    >
                        <div data-zanim-timeline="{}" data-zanim-trigger="scroll">
                            <h5 data-zanim-xs='{"delay":0}'>کامران صابری</h5>
                            <p class="my-4" data-zanim-xs='{"delay":0.1}'>
                                تیم ما، منبع افتخار و خوشحالی است. دعوت می‌کنم تا با انگیزه و توانمندی‌های خود، جزء این خانواده پویا شوید. با هم برای دستیابی به اهداف بزرگ کوشا باشیم!
                            </p>
                            <img
                                className='km-big-boss'
                                data-zanim-xs='{"delay":0.2}'
                                src={`${process.env.PUBLIC_URL}/Assets/img/signature.png`}
                                alt="CEO"
                            />

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
