import React, { useEffect } from 'react'
import { Link, useLoaderData, useLocation } from 'react-router-dom'
import withLoading from '../Assets/hooks/withLoading'
import { store } from '../Redux/store'
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import { Navigation, Autoplay, EffectFade } from 'swiper/modules';
import { setPreloader } from '../Redux/configStore';
import axios from 'axios';
import config from '../Assets/js/config';
import DOMPurify from 'dompurify';
import { Image, Tooltip } from 'antd';
export const loader = async ({ params }) => {
    try {
        store.dispatch(setPreloader(true))
        const { data } = await axios.post(`${config.base}/service`, { title: params.title.replaceAll('-', ' ').toLowerCase() })
        store.dispatch(setPreloader(false))
        console.log(data)
        return { service: data }
    } catch (e) {
        console.error(e)
        throw 'something went wrong!'
    }
    return null
}

export const Service = () => {
    const data = useLoaderData()
    const location = useLocation()

    useEffect(() => {
        window.scrollTo({ top: 1, behavior: 'smooth' })
    }, [location.pathname])

    return (
        <section class="bg-100 km-project-page">
            <div class="container">
                <div class="row g-0 ">
                    <div
                        class="col-lg-4 py-3 py-lg-0 position-relative"
                        style={{ maxHeight: "400px", minHeight: "400px", backgroundPosition: "top" }}

                    >
                        <div
                            className="bg-holder km-personal-nik-name rounded km-personal-image"
                            style={{ backgroundImage: `url(${config.imageBase + '/' + data.service.profileImage})` }}
                        >
                        </div>

                    </div>
                    <div
                        class="col-lg-8 px-4 py-5 my-lg-0 bg-white rounded-lg-te-lg rounded-be-lg rounded-bs-lg rounded-lg-bs-0 d-flex align-items-center">
                        <div className='km-container-birth'>
                            <h5>{data.service.title}</h5>
                            <p
                                class="my-4 km-pers-desc"
                                data-zanim-xs='{"delay":0.1}'
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.service.shortDescription) }}
                            ></p>

                            <img className='km-km-service-main-image' src={`${config.imageBase + '/' + data.service.mainImage}`} />


                            <p
                                className='km-pers-desc'
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.service.longDescription) }}
                            ></p>

                            <h5 className='km-project-technologies-title-km' data-zanim-xs='{"delay":0}'>تکنولوژی ها</h5>

                            <div className='km-project-technologies'>
                                <Swiper
                                    slidesPerView={5}
                                    spaceBetween={40}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay]}
                                    loop
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}

                                >
                                    {data.service.technologies.map(item => (
                                        <SwiperSlide>
                                            {/* {item.icon} */}
                                            <h6 className='km-project-km-title-tech'>{item.title}</h6>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>

                            <h5>نمونه کار ها</h5>
                            <div className='km-project-users-km'>
                                {data.service.projects.map(item => (
                                    <>
                                        <Tooltip placement="top" title={item.name}>
                                            <Link to={`/projects/${item.title.replaceAll(' ', '-')}`} className='km-project-users-km-item'>
                                                <img loading='lazy' src={config.imageBase + '/' + item.logo} />
                                            </Link>
                                        </Tooltip>
                                    </>
                                ))}
                            </div>

                            <Link className='km-project-outer-link' to={data.service.link}>{data.service.link}</Link>

                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
