import React from 'react'
import { useSelector } from 'react-redux'
import { adminNavbar } from '../../Redux/admin'
import { Link } from 'react-router-dom'

export const Navbar = ({ role }) => {
    const navbar = useSelector(adminNavbar)
    return (
        <aside>
            {navbar.filter(item => {
                if (role == 'root') {
                    return item.type != 'member'
                } else {
                    return item.type != 'root'
                }
            }).map(item => (
                <Link to={item.to} key={item.id}>{item.title}</Link>
            ))}
        </aside>
    )
}
