module.exports = {
    isPersian: (text) => {
        const regExp = /[a-zA-Z]/;
        return !regExp.test(text);
    },
    auth: async () => {

    },
    textLength: (text, max) => {
        if (text.length > max) {
            return (text.slice(0, max) + '...')
        }
        return text

    }
}