import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home, { loader as homeLoader } from "../Pages/Home";
import ContactUs from "../Pages/ContactUs";
import About from "../Pages/About";
import Team, { loader as teamLoader } from "../Pages/Team"
import Member from "../Pages/Member";
import { loader as memberLoader } from "../Pages/Member";
import { Notfound } from "../Components/Errors/Notfound";
import Projects, { loader as projectsLoader } from "../Pages/Projects";
import Project, { loader as projectLoader } from '../Pages/Project'
import { AdminLayout } from '../Pages/Admin/AdminLayout'
import { Login } from '../Pages/Admin/Login'
import { Panel, loader as panelLoader } from "../Pages/Admin/Panel";
import { Logout, loader as logoutLoader } from "../Pages/Admin/Logout";
import { AddMember, loader as addMemberLoader } from "../Pages/Admin/AddMember";
import { AddSocial, loader as socialLoader } from "../Pages/Admin/AddSocial";
import { AddAbility, loader as abilityLoader } from "../Pages/Admin/AddAbility";
import { EditProfile, loader as editProfileLoader } from "../Pages/Admin/EditProfile";
import { AddPlatform, loader as platformLoader } from "../Pages/Admin/AddPlatform";
import { AddProject, loader as adminProjectLoader } from "../Pages/Admin/AddProject";
import { AddTechnology, loader as technologyLoader } from "../Pages/Admin/AddTechnology";
import { EditProject, loader as editProjectLoader } from "../Pages/Admin/EditProject";
import { Services, loader as servicesLoader } from "../Pages/Services";
import { AddService, loader as addserviceLoader } from "../Pages/Admin/AddService";
import { EditService, loader as editServiceLoader } from "../Pages/Admin/EditService";
import { Service, loader as serviceLoader } from "../Pages/Service";

export const routes = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '',
                element: <Home />,
                loader: homeLoader,
            },
            {
                path: 'about',
                element: <About />,
            },
            {
                path: 'contactUs',
                element: <ContactUs />,
            },
            {
                path: 'team',
                element: <Team />,
                loader: teamLoader,
            },
            {
                path: 'member/:name',
                element: <Member />,
                loader: memberLoader
            },
            {
                path: 'projects',
                element: <Projects />,
                loader: projectsLoader,
            },
            {
                path: 'projects/:title',
                element: <Project />,
                loader: projectLoader,
            },
            {
                path: 'services',
                element: <Services />,
                loader: servicesLoader
            },
            {
                path: 'service/:title',
                element: <Service />,
                loader: serviceLoader
            },
        ],
        errorElement: <Notfound />,
    },
    {
        path: '/admin',
        element: <AdminLayout />,
        children: [
            {
                path: 'login',
                element: <Login />
            },
            {
                path: 'panel',
                element: <Panel />,
                loader: panelLoader,
            },
            {
                path: 'panel/addmember',
                element: <AddMember />,
                loader: addMemberLoader,
            },
            {
                path: 'panel/addsocial',
                element: <AddSocial />,
                loader: socialLoader,
            },
            {
                path: 'panel/addability',
                element: <AddAbility />,
                loader: abilityLoader,
            },
            {
                path: 'panel/editprofile',
                element: <EditProfile />,
                loader: editProfileLoader,
            },
            {
                path: 'logout',
                element: <Logout />,
                loader: logoutLoader,
            },
            {
                path: 'panel/addplatform',
                element: <AddPlatform />,
                loader: platformLoader
            },
            {
                path: 'panel/addproject',
                element: <AddProject />,
                loader: adminProjectLoader,
            },
            {
                path: 'panel/addtechnology',
                element: <AddTechnology />,
                loader: technologyLoader
            },
            {
                path: 'panel/editproject/:title',
                element: <EditProject />,
                loader: editProjectLoader
            },
            {
                path: 'panel/addservice',
                element: <AddService />,
                loader: addserviceLoader
            },
            {
                path: 'panel/editservice/:title',
                element: <EditService />,
                loader: editServiceLoader
            },
        ],
        errorElement: <Notfound />,
    }
])