import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';

export const Address = () => {
    const form = useRef()


    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_acihwkp', 'template_ldd1eo5', form.current, 'ZZMje2PbTtx1BDPNY')
            .then((result) => {
                console.log(result);
                toast.success('ایمیل شما با موفقیت ارسال شد.')
                form.current.reset()
            }, (error) => {
                console.log(error);
            });
    };


    return (
        <section class="bg-100">
            <div class="container">
                <div class="row align-items-stretch justify-content-center mb-4">
                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <div class="card h-100">
                            <div class="card-body px-5">
                                <h5 class="mb-3">آدرس</h5>
                                <p class="mb-0 text-1100"> لاهیجان, میدان انقلاب,<br />نبش فیاض۴, <br />پاساژ ظروفی</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <div class="card h-100">
                            <div class="card-body px-5">
                                <h5 class="mb-3">ساعت کاری</h5>
                                <p class="mb-0 text-1100"> شنبه تا پنجشنبه  <br />از <br />ساعت ۸ صبح الی ۸ شب</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <div class="card h-100">
                            <div class="card-body px-5">
                                <h5>ما را دنبال کنید:</h5>
                                <Link class="d-inline-block mt-2" to={'https://www.linkedin.com/groups/12851316/'} target='_blank'>
                                    <span class="fab fa-linkedin fs-2 me-2 text-primary"></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body h-100 p-5">
                        <h5 class="mb-3">تماس با ما</h5>
                        <form ref={form} onSubmit={sendEmail}>
                            <div class="mb-4">
                                <input name="user_name" class="form-control bg-white" type="text" placeholder="نام شما" required="required" />
                            </div>
                            <div class="mb-4">
                                <input name="user_email" class="form-control bg-white" type="email" placeholder="ایمیل" required="required" />
                            </div>
                            <div class="mb-4">
                                <textarea name="message" class="form-control bg-white" rows="11" placeholder="پیام خود را اینجا وارد کنید..." required="required"></textarea>
                            </div>

                            <button class="btn btn-md-lg btn-primary" type="Submit">
                                <span class="color-white fw-600">ارسال</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}
