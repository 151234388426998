import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, EffectFade } from 'swiper/modules';

export const Banner = () => {
    return (
        <section className="py-0 km-banner-image">

            <div
                className="bg-holder"
                style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/img/allmembers.webp)` }}></div>
            <div className="container km-banner-slide">
                <div
                    className="row min-vh-100 py-8 align-items-center"
                    data-inertia='{"weight":1.5}'>
                    <div className="col-sm-8 col-lg-7 px-5 px-sm-3"></div>
                </div>
            </div>

            {/* <Swiper
                spaceBetween={50}
                slidesPerView={1}
                loop
                navigation={true}
                modules={[Navigation, Autoplay, EffectFade]}
                autoplay={{
                    delay: 4500,
                    disableOnInteraction: false,
                }}
                effect={'fade'}
                className='km-banner-image'
            > */}
            {/* <SwiperSlide>
                <div
                    className="bg-holder"
                    style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/img/team-slider-1.jpg)` }}></div>
                <div className="container km-banner-slide">
                    <div
                        className="row min-vh-100 py-8 align-items-center"
                        data-inertia='{"weight":1.5}'>
                        <div className="col-sm-8 col-lg-7 px-5 px-sm-3">
                            <div className="overflow-hidden">
                                <h1 className="fs-4 fs-md-5 lh-1" data-zanim-xs='{"delay":0}'>
                                    آژانس خلاقیت آریو ریتم
                                </h1>
                            </div>
                            <div className="overflow-hidden">
                                <p
                                    className="text-primary pt-4 mb-5 fs-1 fs-md-2 lh-xs"
                                    data-zanim-xs='{"delay":0.1}'>
                                    برترین شرکت برگزار کننده دوره های کارآموزی در شرق گیلان
                                </p>
                            </div>
                            <div className="overflow-hidden">
                                <div data-zanim-xs='{"delay":0.2}'>
                                    <Link className="btn btn-primary me-3 mt-3" to="index.html#!">
                                        مشاهده
                                        <span className="fas fa-chevron-right ms-2 mx-2"></span>
                                    </Link>
                                    <Link className="btn btn-warning mt-3" to="/contactUs">تماس باما
                                        <span className="fas fa-chevron-right ms-2 mx-2"></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SwiperSlide>

            <SwiperSlide>


                <div
                    className="bg-holder"
                    style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/img/slider-3.jpg)` }}></div>
                <div className="container km-banner-slide">
                    <div
                        className="row min-vh-100 py-8 align-items-center"
                        data-inertia='{"weight":1.5}'>
                        <div className="col-sm-8 col-lg-7 px-5 px-sm-3">
                            <div className="overflow-hidden">
                                <h1 className="fs-4 fs-md-5 lh-1" data-zanim-xs='{"delay":0}'>
                                    تیمی متشکل از برنامه نویسان جوان
                                </h1>
                            </div>
                            <div className="overflow-hidden">
                                <p
                                    className="text-primary pt-4 mb-5 fs-1 fs-md-2 lh-xs"
                                    data-zanim-xs='{"delay":0.1}'>
                                    تربیت جوانان متخصص در حوزه های مختلف برنامه نویسی
                                </p>
                            </div>
                            <div className="overflow-hidden">
                                <div data-zanim-xs='{"delay":0.2}'>
                                    <Link className="btn btn-primary me-3 mt-3" to="index.html#!">مشاهده
                                        <span className="fas fa-chevron-right ms-2 mx-2"></span>
                                    </Link>
                                    <Link className="btn btn-warning mt-3" to="/contactUs">تماس باما
                                        <span className="fas fa-chevron-right ms-2 mx-2"></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SwiperSlide>



            <SwiperSlide>
                <div
                    className="bg-holder"
                    style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/img/slider-2.jpg)` }}
                ></div>
                <div className="container km-banner-slide">
                    <div
                        className="row min-vh-100 py-8 align-items-center"
                        data-inertia='{"weight":1.5}'>
                        <div className="col-sm-8 col-lg-7 px-5 px-sm-3">
                            <div className="overflow-hidden">
                                <h1 className="fs-4 fs-md-5 lh-1" data-zanim-xs='{"delay":0}'>
                                    آموزش پروژه محور
                                </h1>
                            </div>
                            <div className="overflow-hidden">
                                <p
                                    className="text-primary pt-4 mb-5 fs-1 fs-md-2 lh-xs"
                                    data-zanim-xs='{"delay":0.1}'>
                                    برگزار کننده دوره های آموزش برنامه نویسی مختص به بازار
                                    کار
                                </p>
                            </div>
                            <div className="overflow-hidden">
                                <div data-zanim-xs='{"delay":0.2}'>
                                    <Link className="btn btn-primary me-3 mt-3" to="index.html#!">مشاهده
                                        <span className="fas fa-chevron-right ms-2 mx-2"></span>
                                    </Link>
                                    <Link className="btn btn-warning mt-3" to="/contactUs">تماس باما
                                        <span className="fas fa-chevron-right ms-2 mx-2"></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SwiperSlide> */}

            {/* <div className="swiper-nav">
                    <div className="swiper-button-prev">
                        <span className="fas fa-chevron-left"></span>
                    </div>
                    <div className="swiper-button-next">
                        <span className="fas fa-chevron-right"></span>
                    </div>
                </div> */}
            {/* </Swiper> */}
        </section>
    )
}
