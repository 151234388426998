import axios from 'axios'
import React, { useRef, useState } from 'react'
import { Link, redirect, useLoaderData } from 'react-router-dom'
import config from '../../Assets/js/config'
import { adminNavbar } from '../../Redux/admin'
import { useSelector } from 'react-redux'
import { Navbar } from '../../Components/Admin/Navbar'

export const loader = async () => {
    const token = localStorage.getItem('@token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    try {
        const { data } = await axios.get(`${config.base}/auth/profile`, { headers })
        // const socialMedias = await axios.get(`${config.base}/auth/show-social-medias`, { headers })
        // const abilities = await axios.get(`${config.base}/auth/show-abilities`, { headers })
        if (!data.user || !token) {
            return redirect('/admin/login')
        }
        return {
            user: data.user,
        }
    } catch (e) {
        return redirect('/admin/login')
    }
    return null
}

export const Panel = () => {
    const data = useLoaderData()
    const navbar = useSelector(adminNavbar)
    return (
        <div className='km-admin-panel'>
            <nav>
                <div className='right'>
                    <img src={`${config.imageBase}/${data.user.profile}`} />
                    <h5>{data.user.name}</h5>
                </div>
                <div className='left'>
                    <Link to='/admin/logout'>
                        خروج از حساب کاربری
                    </Link>
                </div>
            </nav>

            <main>
                <Navbar role={data.user.role} />

            </main>

        </div>
    )
}
