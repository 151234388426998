import { Modal } from 'antd'
import axios from 'axios'
import React, { useRef, useState } from 'react'
import config from '../../Assets/js/config'

export const AddMemberModal = ({ open, setOpen, getUsers }) => {
    const name = useRef()
    const mobile = useRef()
    const password = useRef()
    const token = localStorage.getItem('@token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    const [loading, setLoading] = useState(false)


    const addMemer = async () => {
        if (!name.current.value) {
            name.current.style.border = '1px solid red'
            return
        }
        name.current.style.border = '1px solid black'
        if (!mobile.current.value) {
            mobile.current.style.border = '1px solid red'
            return
        }
        mobile.current.style.border = '1px solid black'
        if (!password.current.value) {
            password.current.style.border = '1px solid red'
            return
        }
        password.current.style.border = '1px solid black'
        if (!loading) {
            try {
                setLoading(true)
                const { data } = await axios.post(`${config.base}/auth/register`, {
                    name: name.current.value,
                    mobile: mobile.current.value,
                    password: password.current.value,
                }, { headers })
                setLoading(false)
                setOpen(false)


                getUsers()

                name.current.value = ''
                mobile.current.value = ''
                password.current.value = ''

                console.log(data)
            } catch (e) {
                console.error(e)
            }
        }
    }

    return (
        <Modal
            title="افزودن عضو"
            open={open}
            onOk={addMemer}
            onCancel={() => setOpen(false)}
            cancelText='بیخیال'
            okText='افزودن'
        >
            <div className='add-member-modal-form'>
                <label>نام و نام خانوادگی</label>
                <input disabled={loading} ref={name} type='text' />
                <label>شماره همراه</label>
                <input disabled={loading} ref={mobile} type='tel' />
                <label>گذرواژه</label>
                <input disabled={loading} ref={password} type='password' />
            </div>
        </Modal>
    )
}
