import React from 'react'

export const Welcome = () => {
    return (
        <>
            <section class="bg-white text-center">
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-10 col-md-6">
                            <h3 class="fs-2 fs-lg-3">به آریو ریتم خوش آمدید</h3>
                            <p class="px-lg-4 mt-3">
                                برترین شرکت در حوزه برنامه نویسی در گیلان
                            </p>
                            <hr
                                class="short"
                                data-zanim-xs='{"from":{"opacity":0,"width":0},"to":{"opacity":1,"width":"4.20873rem"},"duration":0.8}'
                                data-zanim-trigger="scroll"
                            />
                        </div>
                    </div>
                    <div class="row mt-4 mt-md-5">
                        <div
                            class="col-sm-6 col-lg-3 mt-4"
                            data-zanim-timeline="{}"
                            data-zanim-trigger="scroll"
                        >
                            <div class="ring-icon mx-auto" data-zanim-xs='{"delay":0}'>
                                <span class="far fa-chart-bar"></span>
                            </div>
                            <h5 class="mt-4" data-zanim-xs='{"delay":0.1}'>
                                طراحی اختصاصی و منحصر به فرد
                            </h5>
                            <p class="mb-0 mt-3 px-3" data-zanim-xs='{"delay":0.2}'>
                                تجربه کاربری جذاب و فوق العاده با طراحی اختصاصی
                            </p>
                        </div>
                        <div
                            class="col-sm-6 col-lg-3 mt-4"
                            data-zanim-timeline="{}"
                            data-zanim-trigger="scroll"
                        >
                            <div class="ring-icon mx-auto" data-zanim-xs='{"delay":0}'>
                                <span class="far fa-bell"></span>
                            </div>
                            <h5 class="mt-4" data-zanim-xs='{"delay":0.1}'>
                                بهره گیری از تکنولوژی های به روز دنیا
                            </h5>
                            <p class="mb-0 mt-3 px-3" data-zanim-xs='{"delay":0.2}'>
                                کدنویسی حرفه ای با به روزترین تکنولوژیها و زبانهای برنامه نویسی
                            </p>
                        </div>
                        <div
                            class="col-sm-6 col-lg-3 mt-4"
                            data-zanim-timeline="{}"
                            data-zanim-trigger="scroll"
                        >
                            <div class="ring-icon mx-auto" data-zanim-xs='{"delay":0}'>
                                <span class="far fa-lightbulb"></span>
                            </div>
                            <h5 class="mt-4" data-zanim-xs='{"delay":0.1}'>
                                پشتیبانی فنی 24 ساعته
                            </h5>
                            <p class="mb-0 mt-3 px-3" data-zanim-xs='{"delay":0.2}'>
                                تست ، ارزیابی و رفع باگها توسط برنامه نویسان حرفه ای در تمامی
                                مراحل انجام پروژه
                            </p>
                        </div>
                        <div
                            class="col-sm-6 col-lg-3 mt-4"
                            data-zanim-timeline="{}"
                            data-zanim-trigger="scroll"
                        >
                            <div class="ring-icon mx-auto" data-zanim-xs='{"delay":0}'>
                                <span class="fas fa-headset"></span>
                            </div>
                            <h5 class="mt-4" data-zanim-xs='{"delay":0.1}'>
                                خدمات توسعه پس از فروش
                            </h5>
                            <p class="mb-0 mt-3 px-3" data-zanim-xs='{"delay":0.2}'>
                                مدیریت و توسعه پروژه در فازهای مختلف با هدف رشد کسب و کار
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section class="pt-0">
                <div class="container">
                    <div class="row flex-center text-center pb-6">
                        <div class="col-12">
                            <div class="position-relative mt-4 py-5 py-md-11">
                                <div
                                    class="bg-holder rounded-3 "
                                    style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/img/team-slider-1.jpg)`
                                    }}></div>
                                {/* <button
                                    class="btn-elixir-play km-center-group-img"
                                >
                                    <span class="fas fa-play fs-1"></span>
                                </button> */}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="col-sm-6 col-lg-4 mt-3 mt-lg-0 px-4 px-sm-3"
                            data-zanim-timeline="{}"
                            data-zanim-trigger="scroll"
                        >
                            <h5 data-zanim-xs='{"delay":0}'>
                                <span class="text-primary mx-2 me-2 fas fa-users"></span>
                                نیاز سنجی ، ارزیابی و برنامه ریزی پروژه
                            </h5>
                            <p class="mt-3 pe-3 pe-lg-5" data-zanim-xs='{"delay":0.1}'>
                                در این مرحله بعد از دریافت و مطالعه کلیه مستندات موجود و برگزاری جلسات مشترک با کارشناسان و تحلیل اطلاعات برنامه ریزی طراحی سیستم انجام می شود.
                            </p>
                        </div>
                        <div
                            class="col-sm-6 col-lg-4 mt-3 mt-lg-0 px-4 px-sm-3"
                            data-zanim-timeline="{}"
                            data-zanim-trigger="scroll"
                        >
                            <h5 data-zanim-xs='{"delay":0}'>
                                <span class="text-primary mx-2 me-2 fas fa-comments"></span>طراحی ، پیاده سازی و اجرای پروژه
                            </h5>
                            <p class="mt-3 pe-3 pe-lg-5" data-zanim-xs='{"delay":0.1}'>
                                در این مرحله مدلهای نرم افزاری و مستندات لازم، طراحی، پیاده سازی و تجزیه و تحلیل پایگاه داده، برنامه نویسی و در نهایت تست و ارزیابی  سیستم انجام می شود.
                            </p>
                        </div>
                        <div
                            class="col-sm-6 col-lg-4 mt-3 mt-lg-0 px-4 px-sm-3"
                            data-zanim-timeline="{}"
                            data-zanim-trigger="scroll"
                        >
                            <h5 data-zanim-xs='{"delay":0}'>
                                <span class="text-primary mx-2 me-2 fas fa-bolt"></span>
                                تحویل ، آموزش و پشتیبانی پروژه
                            </h5>
                            <p class="mt-3 pe-3 pe-lg-5" data-zanim-xs='{"delay":0.1}'>
                                پس از اجرای کامل، آموزش جامع و کاربردی در اختیار کاربران قرار می گیرد. همچنین می توانید برای انجام اصلاحات لازم از خدمات پشتیبانی بهره بگیرید.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
