import axios from 'axios'
import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

export const Login = () => {
    const navigate = useNavigate()
    const mobile = useRef()
    const password = useRef()

    const handleLogin = async () => {
        try {
            const { data } = await axios.post('https://api.aryorithm.ir/public/api/auth/login', {
                mobile: mobile.current.value,
                password: password.current.value
            })
            console.log(data)
            localStorage.setItem('@token', data.access_token)
            navigate('/admin/panel')
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <div className='km-admin-login-wrapper'>
                <div className='km-admin-login'>
                    <h5>Login</h5>
                    <label>mobile:</label>
                    <input ref={mobile} type='tel' />
                    <label>password</label>
                    <input ref={password} type='password' />
                    <input type='submit' value='Login' onClick={handleLogin} />
                </div>
            </div>
        </>
    )
}
