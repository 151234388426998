// withLoading.js
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Loading } from '../../Components/Loading';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { preloader, setPreloader } from '../../Redux/configStore';

const withLoading = (WrappedComponent) => {
    return (props) => {
        const [loading, setLoading] = useState(true);
        const _preloader = useSelector(preloader)
        const dispatch = useDispatch()

        // useEffect(() => {
        //     const loadingTimeOut = setTimeout(() => {
        //         setLoading(false)
        //     }, 800);
        //     return () => clearTimeout(loadingTimeOut)
        // }, [])



        return _preloader ? <Loading /> : <WrappedComponent {...props} />;
    };
};

export default withLoading;
