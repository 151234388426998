import React, { useEffect, useLayoutEffect } from 'react'
import { Personal } from '../Components/Member/Personal'
import withLoading from '../Assets/hooks/withLoading'
import { useLoaderData, useLocation, useParams } from 'react-router-dom'
import { store } from '../Redux/store'
import { Helmet } from 'react-helmet'
import config from '../Assets/js/config'
import axios from 'axios'
import DOMPurify from 'dompurify'
import { setPreloader } from '../Redux/configStore'

export const loader = async (data) => {
    const { params } = data
    // const member = store.getState().team.members.find(item => item.id == params.id)
    // return member
    try {
        store.dispatch(setPreloader(true))
        const { data } = await axios.post(`${config.base}/user`, {
            name: params.name.replaceAll('-', ' ')
        })
        const clearedData = {
            id: data.id,
            address: data.address,
            birthday: data.birthday,
            longDescription: data.longDescription,
            mobile: data.mobile,
            name: data.name,
            nickname: data.nickname,
            profile: data.profile,
            role: data.role,
            shortDescription: data.shortDescription,
            title: data.title,
            abilities: [],
            social_media: [],
        }
        data.abilities.forEach(element => {
            clearedData.abilities.push({ id: element.id, title: element.title, value: element.pivot.value })
        });
        data.social_media.forEach(element => {
            clearedData.social_media.push({ id: element.id, title: element.title, link: element.pivot.link })
        });
        store.dispatch(setPreloader(false))
        return clearedData
    } catch (e) {
        console.error(e)
        throw 'something went wrong!'
    }
    return null

}

const Member = () => {
    const data = useLoaderData()
    const location = useLocation()

    useEffect(() => {
        window.scrollTo({ top: 1, behavior: 'smooth' })
    }, [location.pathname])

    return (
        <>
            <Helmet>
                <title>{data.name}</title>
            </Helmet>

            <Personal
                image={data.profile}
                name={data.name}
                shortDesc={DOMPurify.sanitize(data.shortDescription)}
                abilities={data.abilities}
                description={DOMPurify.sanitize(data.longDescription)}
                nikName={data.nickname}
                social={data.social_media}
                career={data.title}
                birth={data.birthday}
            />
        </>
    )
}


export default withLoading(Member)